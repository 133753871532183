import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import React from 'react';
import {SingleSource,WorkItems} from '../utility';
import WorkFooter from '../components/workfooter';
import Context from '../components/context';

export default function WorkPage() {
    const workItem = WorkItems();

    return (
        <div className='work-fontColor1'>
            <Container className='pt-5 mt-5 work'>
                <div className='subTitle text-start'>Responsive Website Design</div>
                <h1 className='mainTitle text-start py-2'>{workItem.title}</h1>
                <div className='mainDes text-start pb-4'>Commerdash Inc. is a full-service agency that drives digital transformation. With the massive number of competitors,&nbsp;<span className='workBold'>the challenge is to showcase professionalism persuasively</span>.
                </div>
                <Row className='py-5 text-start'>
                    <Col lg sm='12' className='py-3'>
                        <div className='workBold2'>
                            Client
                        </div>
                        <div>
                            Commerdash
                        </div>
                    </Col>
                    <Col lg sm='12' className='py-3'>
                        <div className='workBold2'>
                            Role
                        </div>
                        <div>
                            UX Desinger
                        </div>
                    </Col>
                    <Col lg sm='12' className='py-3'>
                        <div className='workBold2'>
                            Time
                        </div>
                        <div>
                            2021
                        </div>
                    </Col>
                    <Col lg='5' sm='12' className='py-3'>
                        <div className='workBold2'>
                            Team
                        </div>
                        <div>
                            1 Developer, 1 UX Designer, 1 Business Analyst
                        </div>
                    </Col>
                </Row>
            </Container>
            <section id="section-2">
                <Container fluid className='pb-4'>
                    <img alt='' width="100%" src={`/img/${workItem.title.toLocaleLowerCase()}/cover.jpg`}/>
                </Container>
            </section>
            <section>
                <Container className='py-5 my-5 work'>
                    <div className='mainbg'>
                        <Row>
                            <Col lg sm='12' className='text-start py-2'>
                                <div className='workBoldItalic pt-5' id="context">
                                    Context & Challenge
                                </div>
                                <div className='workBold3 pb-5 mb-5 w-75'>
                                    A sophisticated website is needed due to the change in service scope
                                </div>
                                <div>
                                    <a className='worklink' href='http://www.commerdash.com' rel="noreferrer" target='_blank'>Check CommerDash.com</a>
                                </div>
                            </Col>
                            <Col lg sm='12' className='text-start py-2'>
                                <div className='text-start pt-5'>
                                    &nbsp;
                                </div>
                                <div className='pb-3 mb-5'>
                                    CommerDash used to focus on Azure and SharePoint consulting and was referrals exclusive. The agency recently recruited an E-Commerce team and expanded its services scope. The shareholders now hope to open the door and build more digital exposure.
                                </div>
                                <div className='workBold2'>
                                    Goals & Metrics
                                </div>
                                <div>
                                    •	Create an attractive onboarding experience.
                                    <br></br>
                                    •	Promote users to contact.
                                    <br></br>
                                    •	Streamline info finding.
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
            <section>
                <Container fluid className='py-5 mt-5 altbg'>
                    <div className='mainbg'>
                        <Container className='py-5 mt-5 work'>
                            <Row>
                                <Col lg sm='12' className='text-start py-5 mt-5'>
                                    <div className='workBoldItalic pt-5' id="competitive_audit">
                                        Competitive audit
                                    </div>
                                    <div className='workBold3 pb-2 mb-2'>
                                        Search the Web
                                    </div>
                                    <div className='pb-4 mb-4 w-75'>
                                        Hoping to understand the successful patterns and
                                        possible gaps, the first thing I did was conduct a
                                        competitive audit.
                                    </div>
                                    <div className='workBoldItalic pt-5' id="interview">
                                        Interview
                                    </div>
                                    <div className='workBold3 pb-2 mb-2'>
                                        Talk to People
                                    </div>
                                    <div className='pb-4 mb-4 w-75'>
                                        We recruited 6 participants (3 existing clients and 3
                                        prospective ones), one service agency, two
                                        professional service companies, and three retail. 
                                        <br></br>
                                        <br></br>
                                        I asked the participants to browse competitors’
                                        websites, and hope to find the answers to:
                                        <br></br>
                                        1.	What consolidated the decision to work with the agency?
                                        <br></br>
                                        2.	What information do people think is important to show?
                                        <br></br>
                                        3.	What made the experience positive/ negative?
                                        <br></br>
                                        <br></br>
                                        I interviewed participants, created sympathy maps
                                        and converted one-user empathy maps into two
                                        aggregated ones. 
                                    </div>
                                </Col>
                                <Col lg sm='12' className='text-middle py-5 mt-5' id="empathy_map">
                                    <div className='workItalic pt-5 pb-3'>
                                        Aggregated empathy maps
                                    </div>
                                    <img alt='' width="65%" src={`/img/${workItem.title.toLocaleLowerCase()}/empathymap.png`} />
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </Container>
            </section>
            <section id="section-5">
                <Container fluid className='altbg'>
                    <div>
                        <Container className='work'>
                            <Row>
                                <Col lg='12' md='12' sm='12' className='text-start py-5' id="personas">
                                    <div className='workBold pb-2 mb-2'>
                                        Two types of users: <span className='workBold3'>Medium-sized & Start-up</span>
                                    </div>
                                </Col>
                                <Col lg='6' md='6' sm='12' className='text-start pb-5'>
                                    <div className='pb-4 mb-4'>
                                        Personify the empathy maps. I identified two types of target users. Persona Andrew represents users who value industry experience and referrals, and personal Anna for start-ups with a limited budget. 
                                    </div>
                                    <SingleSource src={`/img/${workItem.title.toLocaleLowerCase()}/personaanna.png`} />
                                </Col>
                                <Col lg='6' md='6' sm='12' className='text-start pb-5'>
                                    <SingleSource src={`/img/${workItem.title.toLocaleLowerCase()}/personaandrew.png`} />
                                    <div className='py-4 my-4' id='define'>
                                        <div className='workBoldItalic'>Define</div>
                                        Through user journey maps, I identified&nbsp;
                                        <span className='workBold2'>
                                        the 4 key factors that affect users’ decision-making are 	credentials, cost, credibility & Industry experience.
                                        </span>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </Container>
            </section>
            <section id="section-6">
                <Container fluid className='py-5 altbg'>
                    <div className='mainbg'>
                        <Container className='py-5 work'>
                            <Row>
                                <Col lg='12' md='12' sm='12' className='text-start py-5'>
                                    <div className='workBoldItalic' id='user_flow'>
                                        Ideation
                                    </div>
                                    <div className='workBold3 pb-2 mb-2'>
                                        The homepage has the highest bouncing rate, and the projects page has the longest “time on page”
                                    </div>
                                    <div className='workItalic py-4 my-4'>User flow</div>
                                    <SingleSource src={`/img/${workItem.title.toLocaleLowerCase()}/userflow.png`} />
                                </Col>
                                <Col lg='6' md='6' sm='12' className='text-start pb-5'>
                                    <div className='py-4 my-4 w-75'>
                                        To supply the information that clients look for, I decided to put focus on the Projects section. After some sketches, we went with a simple hieratical sitemap mode. 
                                    </div>
                                    <div className='workItalic text-start pb-4'>
                                        Iteration
                                    </div>
                                    <SingleSource src={`/img/${workItem.title.toLocaleLowerCase()}/wireframe.png`} />
                                </Col>
                                <Col lg='6' md='6' sm='12' className='text-start pb-5'>
                                    <div className='workItalic pt-4 mt-4 w-75'>
                                        Sitemap
                                    </div>
                                    <SingleSource src={`/img/${workItem.title.toLocaleLowerCase()}/sitemap.png`} />
                                    <div className='py-5 my-5 px-5'>
                                        Though a very basic design, the process still involved iterations. We conducted a usability test and made adaptions based on the findings. One prominent change was adding filter to project section on the homepage to save users some clicks.
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </Container>
            </section>
            <section id="section-7">
                <Container fluid className='altbg pb-5'>
                    <div>
                        <Container className='work'>
                            <Row>
                                <Col lg='12' md='12' sm='12' className='text-start py-5' id='prototype'>
                                    <div className='workItalic pb-2 mb-2'>
                                        Visual & Prototype
                                    </div>
                                    <div className='workBold3 pb-4 mb-4'>
                                        Professionalism + Aesthetics = Sales
                                    </div>
                                    <div className='pb-4 mb-4'>
                                        After validating our Lo-Fi prototype, I moved into the Hi-Fi phase. At this point, I turned my focus to branding and visual design and then flushed out the hi-fi prototype for validation testing. Stylish hues and micro animations come together to set a mood and submerge the users. 
                                    </div>
                                    <div className='workItalic'>
                                        Animated Prototype
                                    </div>
                                    <SingleSource src={`/img/${workItem.title.toLocaleLowerCase()}/prototype.gif`} />
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </Container>
            </section>
            <section id="section-8">
                <Container fluid>
                    <div className='mainbg py-5'>
                        <Container className='work'>
                            <Row>
                                <Col lg='12' md='12' sm='12' className='text-start' id='style_guide'>
                                    <div className='workItalic'>
                                        Visual guide
                                    </div>
                                    <img alt='' width="100%" src={`/img/${workItem.title.toLocaleLowerCase()}/styleguide.png`} />
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </Container>
            </section>
            <section id="section-9">
                <Container fluid>
                    <div className='mainbg py-5'>
                        <Container className='work'>
                            <Row>
                                <Col lg='12' md='12' sm='12' className='text-start'>
                                    <div className='workItalic' id='validation'>
                                        Validation
                                    </div>
                                    <div className='workBold3 pb-2 mb-2'>
                                        Overall positive feedback
                                    </div>
                                    <div className='pb-4 mb-4'>
                                        We conducted validation testing of the clickable prototype with the same 6 users
                                        <br></br>
                                        <br></br>
                                        •	3 out of 3 existing clients have better understandings of the service scope and feel more confident to continue working with Commerdash.
                                        <br></br>
                                        •	2 out of 3 prospective clients considered the design can encourage users to work with Commerdash.
                                        <br></br>
                                        •	4 out of 6 participants found the overall interaction was more intuitive than competitors.  
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </Container>
            </section>
            <section id="section-10">
                <Container fluid className='py-5 altbg'>
                    <div>
                        <Container className='py-5 work'>
                            <Row>
                                <Col lg='12' md='12' sm='12' className='text-start'>
                                    <div className='workItalic' id='conclusion'>
                                        Conclusion & Afterthought
                                    </div>
                                    <div className='workBold pb-4 mb-4'>
                                        Even for the simplest work, <span className='workBold3'>never jump to pre-determined solutions.</span>
                                    </div>
                                    <div>
                                        Overall, the prototype proved positive impact on users’ confidence in working with the company. The design supplied a clear information architecture and introduced a prompt way for users to locate information.
                                        <br></br>
                                        <br></br>
                                        In the beginning, all stakeholders thought it was going to be a straightforward process and universal design, and I was the only one who insisted on doing research. From the competitor's audit, I found the websites of competitors were extremely similar.
                                        <span className='workBold2'>What I learned is that upholding standards doesn’t mean we should stop exploring the opportunity to customize the experience according to users’ needs.</span>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </Container>
            </section>
            <Container className='work workFooter'>
                <WorkFooter workPrevious={workItem.workPrevious} workNext={workItem.workNext} />
            </Container>
            <Context workPrevious={workItem.workPrevious} workNext={workItem.workNext} contextItems={workItem.contextItems} />
        </div>
    );
  }