import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import {BrowserRouter,Routes,Route} from "react-router-dom";
import App from './App';
import ScrollToTop from './ScrollToTop';
import About from "./pages/about";
import Bonding from "./pages/bonding";
import SBDC from "./pages/sbdc";
import Sipps from "./pages/sipps";
import WT from "./pages/wt";
import FOTG from "./pages/fotg";
import Home from "./pages/home";
import CommerDash from "./pages/commerdash";
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ScrollToTop>
        <Routes>
          <Route path="/" element={<App />}>
            <Route index element={<Home />} />
            <Route path="about" element={<About />} />
            <Route path="bonding" element={<Bonding />} />
            <Route path="sbdc" element={<SBDC />} />
            <Route path="sipps" element={<Sipps />} />
            <Route path="sbdc" element={<SBDC />} />
            <Route path="WT%20System" element={<WT />} />
            <Route path="fotg" element={<FOTG />} />
            <Route path="commerdash" element={<CommerDash />} />
          </Route>
        </Routes>
      </ScrollToTop>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
