import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import React from 'react';
import {SingleSource,WorkItems} from '../utility';
import WorkFooter from '../components/workfooter';
import Context from '../components/context';

export default function WorkPage() {
    const workItem = WorkItems();

    return (
        <>
            <Container className='py-5 my-5 work'>
                <div className='subTitle text-start'>Mini Program Design</div>
                <h1 className='mainTitle text-start py-2'>{workItem.title}</h1>
                <div className='mainDes text-start pb-4'>
                China's population is aging faster than almost all other countries in modern history, and more than half of the seniors regularly experience loneliness.
                    &nbsp;<span className='workBold'>The challenge is to build a senior-friendly digital product that nurtures family relationships</span>.
                </div>
                <Row className='py-5 text-start'>
                    <Col lg sm='12' className='py-3'>
                        <div className='workBold2'>
                            Client
                        </div>
                        <div>
                            Chinese Silver Industry Association 
                        </div>
                    </Col>
                    <Col lg sm='12' className='py-3'>
                        <div className='workBold2'>
                            Role
                        </div>
                        <div>
                            UX Designer
                        </div>
                    </Col>
                    <Col lg sm='12' className='py-3'>
                        <div className='workBold2'>
                            Time
                        </div>
                        <div>
                            2021
                        </div>
                    </Col>
                    <Col lg sm='12' className='py-3'>
                        <div className='workBold2'>
                            Team
                        </div>
                        <div>
                            1 Product Manager, 2 Developer, 1 UX Designer, 1 Researcher
                        </div>
                    </Col>
                </Row>
            </Container>
            <section id="section-2">
                <Container fluid className='pb-4'>
                    <img alt=''width="100%" src={`/img/${workItem.title.toLocaleLowerCase()}/cover.jpg`}/>
                    <Container className='work pt-4 nda'>
                        *To comply with my non-disclosure agreement, I have omitted and obfuscated confidential information in this case study. 
                    </Container>
                </Container>
            </section>
            <section>
                <Container className='py-5 my-5 work'>
                    <div className='mainbg'>
                        <Row>
                            <Col lg sm='12' className='text-start py-2'>
                                <div className='workBoldItalic pt-5' id="context">
                                    Context & Challenge
                                </div>
                                <div className='workBold3 pb-5 w-75'>
                                    Seniors are lonely
                                </div>
                                <div className='workText2 w-75 py-5'>
                                    If you use WeChat, check "Bonding" by scanning the QR code below.
                                </div>
                                <div className='workText2 w-25'>
                                    <img alt=''width="100%" src={`/img/${workItem.title.toLocaleLowerCase()}/qr.png`}/>
                                </div>
                            </Col>
                            <Col lg sm='12' className='text-start py-2'>
                                <div className='text-start pt-5'>
                                    &nbsp;
                                </div>
                                <div className='pb-3 mb-5'>
                                    The project was initiated by the Chinese Silver Industry Association. The association advocates for senior citizens' wellness in all aspects. In response to the growing public health concern of social isolation and loneliness among seniors, the association hoped to develop an app that keeps family members connected, with the opportunity to evolve into a profitable business model by including an e-commerce function.
                                </div>
                                <div className='workBold2'>
                                    Goals & Metrics
                                </div>
                                <div>
                                    •	User acquisition, growth rate (MAU & DAU).
                                    <br></br>
                                    •	Impressions & uploads.
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
            <section>
                <Container fluid>
                    <img alt=''width="100%" src={`/img/${workItem.title.toLocaleLowerCase()}/breaker.png`}/>
                </Container>
            </section>
            <section>
                <Container className='py-5 my-5 work' id='surveys'>
                    <div className='mainbg'>
                        <Row>
                            <Col className='text-start py-2'>
                                <div className='workBoldItalic pt-5' >
                                    Secondary research & survey #1
                                </div>
                                <div className='workBold3'>
                                    Statistics said app, an ethnographic study told me not really
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='text-start py-2'>
                                <a className='workText2' target='_blank' rel="noreferrer" href={`/img/${workItem.title.toLocaleLowerCase()}/Bonding UX Research Plans.pdf`}>Bonding research plans</a>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='text-start py-2'>
                                <div>
                                    I started the project with secondary research as I doubted if the app was the ideal vehicle. I was able to validate that for the age group  of 55+, smartphone penetration is way higher than any other digital device. So, it seems like a mobile phone app should be the best digital solution. 
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg xs='12' className='text-start'>
                                <div className='pt-5'>
                                    However, after talking with 5 seniors, I found that 4/5 have technical difficulties downloading apps, and 2/5 felt challenging to register an account. The finding instantly shut the door of using mobile app as solution.
                                </div>
                                <div className='pt-5'>
                                    At this time, I came up with the idea of making a WeChat mini program. WeChat mini-programs are “sub-applications” within the WeChat ecosystem and can be used on WeChat without downloading or installing. WeChat has 1.24 million users, and almost every smartphone user in China has an account.
                                </div>
                                <div className='pt-5'>
                                    I drafted a survey hoping to understand the feasibility of using the mini program as a solution. The researchers recruited 30 participants from two senior centers. <span className='workBold2'>The results showed users (29/30) are more willing to use the WeChat mini program, especially when sign-up is a must</span>. The findings reaffirmed our decision to build a mini program.
                                </div>
                            </Col>
                            <Col lg xs='12'>
                                <div className='pt-5 mx-auto'>
                                    Facilitator prepares for survey
                                </div>
                                <div className='pt-2 w-75 mx-auto'>
                                    <SingleSource alt='' src={`/img/${workItem.title.toLocaleLowerCase()}/survey.png`} />
                                </div>
                                <div className='pt-2 w-75 mx-auto'>
                                    <SingleSource alt='' src={`/img/${workItem.title.toLocaleLowerCase()}/research.png`} />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
            <section>
                <Container fluid className='py-5 my-5 work'>
                    <Row className='mx-0'>
                        <Col className='px-0'>
                            <div className='banner2 leftbanner2'>
                            </div>
                        </Col>
                        <Col lg='10' md='10' sm='10' xs='10' className='px-0'>
                            <Container>
                                <Row>
                                    <Col className='text-start py-2'>
                                        <div className='workBoldItalic pt-5' >
                                            Survey #2
                                        </div>
                                        <div className='workBold3'>
                                            People want to grow stronger family relationships, but find it challenging
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='text-start py-2'>
                                        <div className='pt-5'>
                                        Next, we conducted another survey to identify users' needs and wants. We surveyed the same groups of seniors and some younger participants through social media. <span className='workBold2'>The result provided a peek at the root of the problem - time</span>. Younger participants want to nurture relationships without spending much time. 
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg xs='12' className='pt-4 pr-4'>
                                        <SingleSource alt='' src={`/img/${workItem.title.toLocaleLowerCase()}/survey_2_1.png`} />
                                    </Col>
                                    <Col lg xs='12' className='pt-4 pr-4'>
                                        <SingleSource alt='' src={`/img/${workItem.title.toLocaleLowerCase()}/survey_2_4.png`} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg xs='12' className='pt-4 pr-4'>
                                        <SingleSource alt='' src={`/img/${workItem.title.toLocaleLowerCase()}/survey_2_2.png`} />
                                    </Col>
                                    <Col lg xs='12' className='pt-4 pr-4'>
                                        <SingleSource alt='' src={`/img/${workItem.title.toLocaleLowerCase()}/survey_2_3.png`} />
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                        <Col className='px-0'>
                            <div className='banner2 rightbanner2 float-end'>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section>
                <Container className='work' id='improve_2'>
                    <Row className='py-5 mt-5'>
                        <Col className='text-start pt-5 mt-5 lgImg' id="personas">
                            <div className='workBold pb-2 mb-2'>
                                Two types of users: <span className='workBold3'>Senior & the Family</span>
                            </div>
                        </Col>
                        <Col className='text-start pt-5 mt-5 smImg' id="personas">
                            <div className='workBold pb-2 mb-2'>
                                Two types of users:
                                <br></br>
                                <span className='workBold3'>Senior & the Family</span>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='pb-5 mb-5 lgImg'>
                            <div className='overlapimg'>
                                <SingleSource src={`/img/${workItem.title.toLocaleLowerCase()}/personaandrew.png`} />
                            </div>
                            <div className='overlapimg overlapimg-right'>
                                <SingleSource src={`/img/${workItem.title.toLocaleLowerCase()}/personaanna.png`} />
                            </div>
                        </Col>
                        <Col  className='pb-5 mb-5 smImg'>
                            <div className='w-75 mx-auto pb-5 mb-5'>
                                <SingleSource src={`/img/${workItem.title.toLocaleLowerCase()}/personaandrew.png`} />
                            </div>
                            <div className='w-75 mx-auto'>
                                <SingleSource src={`/img/${workItem.title.toLocaleLowerCase()}/personaanna.png`} />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='text-start py-2' id='journey_maps'>
                            <div className='workBoldItalic pt-5'>
                                User Journey Maps
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='pb-5 mb-5 lgImg'>
                            <div className='overlapimg'>
                                <SingleSource src={`/img/${workItem.title.toLocaleLowerCase()}/uj_1.png`} />
                            </div>
                            <div className='overlapimg overlapimg-right'>
                                <SingleSource src={`/img/${workItem.title.toLocaleLowerCase()}/uj_2.png`} />
                            </div>
                        </Col>
                        <Col  className='pb-5 mb-5 smImg'>
                            <div className='w-75 mx-auto pb-5 mb-5'>
                                <SingleSource src={`/img/${workItem.title.toLocaleLowerCase()}/uj_1.png`} />
                            </div>
                            <div className='w-75 mx-auto'>
                                <SingleSource src={`/img/${workItem.title.toLocaleLowerCase()}/uj_2.png`} />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section>
                <Container fluid className='py-5 my-5 work' id='define'>
                    <Row className='mx-0'>
                        <Col className='px-0'>
                            <div className='banner2 leftbanner2'>
                            </div>
                        </Col>
                        <Col lg='10' md='10' sm='10' xs='10' className='px-0'>
                            <Container>
                                <Row>
                                    <Col className='text-start py-2'>
                                        <div className='workBoldItalic pt-5' >
                                            Define
                                        </div>
                                        <div className='workBold3'>
                                            How might users stay connected without less time investment?
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg xs='12' className='pt-4 pr-4'>
                                        <SingleSource alt='' src={`/img/${workItem.title.toLocaleLowerCase()}/define.png`} />
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                        <Col className='px-0'>
                            <div className='banner2 rightbanner2 float-end'>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section>
                <Container className='py-5 work' id='ideation'>
                    <Row>
                        <Col lg='12' md='12' sm='12' className='text-start py-5'>
                            <div className='workBoldItalic' id='user_flow'>
                                Ideation
                            </div>
                            <div className='workBold3 pb-2 mb-2'>
                                An invite-only sharing platform that features a senior-friendly design
                            </div>
                            <div className='pb-2 mb-2'>
                                I set the needs of the personas as the goal my product must help users accomplish, and have the product defined: post instead of chatting. Following the flows, I sketched paper wireframes and then rendered them digitally. The skeletal framework let me focus on the big picture and functionality.
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section>
                <Container fluid className='altbg2 py-5' id='competitive_audit'>
                    <div>
                        <Container className='work'>
                            <Row>
                                <Col lg='12' md='12' sm='12' className='text-start py-5' >
                                    <div className='workBoldItalic pb-2 mb-2'>
                                        Competitive audit
                                    </div>
                                    <div className='workBold3 pb-4 mb-4'>
                                        I found 0 direct competitors, which means we either have the first-mover advantage or 0 market 
                                    </div>
                                    <div>
                                        Moving forward, I conducted a competitive audit; surprisingly, I didn't find any director competitors. The closest things are invite-only instant messaging apps and family jar apps. This made us start to doubt if such a product didn't have a market need in the first place. Apprehensively, we ran the survey with another two groups of people, yet the results concluded the same. Reassured, we decided to move forward. 
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </Container>
            </section>
            <section>
                <Container className='work py-5 mt-5' id='iteration'>
                    <Row>
                        <Col className='text-start py-5'>
                            <div className='workBoldItalic pb-2 mb-2'>
                                Iteration
                            </div>
                            <div className='workBold3 pb-4 mb-4'>
                                The mental model gaps
                            </div>
                            <div className='pb-4 mb-4'>
                            With the lo-fi prototype, I conducted my first usability study with eight participants (four aged 55+ and four of age 25-55) and made changes accordingly.
                            <br></br><br></br>
                            •	Moving the accessible mode switch to a more prominent place as only 1 senior participant found it. 
                            <br></br><br></br>
                            •	Removed functions like ”edit photo”, as seniors rarely use these functions and found them confusing.
                            <br></br><br></br>
                            •	Adding an in-applet countdown calendar, as 3 participants reported that the “auto-adding to calendar” function barely helped. by default, the phone calendar sends reminders only 15min prior. 
                            <br></br><br></br>
                            •	Sticking the major call-to-action button “add post” to the bottom app bar to provide quick access and to render a sharing environment. 
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='py-5 lgImg'>
                            <SingleSource alt='' src={`/img/${workItem.title.toLocaleLowerCase()}/user_flow.png`} />
                        </Col>
                        <Col className='py-5 smImg'>
                            <SingleSource alt='' src={`/img/${workItem.title.toLocaleLowerCase()}/user_flow_m.png`} />
                        </Col>
                    </Row>
                    <Row className='align-items-center'>
                        <Col lg xs='12' className='py-5 px-5'>
                            <SingleSource alt='' src={`/img/${workItem.title.toLocaleLowerCase()}/iteration_1.png`} />
                        </Col>
                        <Col lg xs='12' className='py-5 px-5'>
                            <SingleSource alt='' src={`/img/${workItem.title.toLocaleLowerCase()}/iteration_2.png`} />
                        </Col>
                    </Row>
                    <Row id='wireframe' className='py-3 mt-5'>
                        <Col lg xs='12' className='text-center px-5 workBold4'>
                            Two iterations on wireframes
                        </Col>
                    </Row>
                    <Row className='align-items-center mb-5'>
                        <Col lg xs='12'>
                            <SingleSource alt='' src={`/img/${workItem.title.toLocaleLowerCase()}/wireframe_1.png`} />
                        </Col>
                        <Col lg xs='12'>
                            <SingleSource alt='' src={`/img/${workItem.title.toLocaleLowerCase()}/wireframe_2.png`} />
                        </Col>
                        <Col lg xs='12'>
                            <SingleSource alt='' src={`/img/${workItem.title.toLocaleLowerCase()}/wireframe_3.png`} />
                        </Col>
                    </Row>
                    <Row className='align-items-center'>
                        <Col className='py-5 mt-5 lgImg'>
                            <SingleSource alt='' src={`/img/${workItem.title.toLocaleLowerCase()}/iteration_3.png`} />
                        </Col>
                        <Col className='py-5 mt-5 smImg'>
                            <SingleSource alt='' src={`/img/${workItem.title.toLocaleLowerCase()}/iteration_3_m.png`} />
                        </Col>
                    </Row>
                    <Row className='align-items-center'>
                        <Col className='py-5 mt-5 lgImg'>
                            <SingleSource alt='' src={`/img/${workItem.title.toLocaleLowerCase()}/workstream_1.png`} />
                        </Col>
                    </Row>
                </Container>
            </section>
            <section>
                <Container fluid className='altbg2 py-5'>
                    <div>
                        <Container className='work'>
                            <Row>
                                <Col lg='8' xs='12' className='text-start py-5'>
                                    <div className='workBoldItalic pb-2 mb-2'>
                                        Branding
                                    </div>
                                    <div className='workBold3 pb-4 mb-4'>
                                        Understandable and recognizable
                                    </div>
                                    <div>
                                        Following, I paused for the brand book. After some sketches, we settled on the hugging heart logo, and the marketing name "千盼", which means "long for". Both the name and the logo reflect our vision to bond family members closer. Color-wise, I chose Chinese red for the heart and subtle orange to add a touch of warmth. I deliberately made the logo eye-catching and easy to understand so users can recognize it with ease.  
                                    </div>
                                </Col>
                                <Col lg xs='12' className='py-5 align-self-end'>
                                    <SingleSource src={`/img/${workItem.title.toLocaleLowerCase()}/logo.png`} />
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </Container>
            </section>
            <section>
                <Container className='work py-5 mt-5' id='visual'>
                    <Row>
                        <Col className='text-start py-5'>
                            <div className='workBoldItalic pb-2 mb-2'>
                                User interface
                            </div>
                            <div className='workBold3 pb-4 mb-4'>
                                Text legibility, high contrast, and dark mode
                            </div>
                            <div className='pb-4 mb-4'>
                                <span className='workBold2'>UI Challenge 1: Seniors are less tech-savvy and have decreased cognitive loads.</span>
                                <br></br>
                                Seniors use a limited number of apps. To ensure the design is understandable, I must adopt the interfaces they are familiar with. And the answer is obvious - use the WeChat UI Kit.
                                <br></br><br></br>
                                <span className='workBold2'>UI Challenge 2: Most seniors experience presbyopia.</span>
                                <br></br>
                                Reasonably large font sizes and high contrast can help users with presbyopia, a form of farsightedness.
                                <br></br><br></br>
                                <span className='workBold2'>UI Challenge 3: Some seniors are troubled by cataracts.</span>
                                <br></br>
                                Users with cataracts, a problem caused by cloudy ocular media, often have problems processing light interfaces. Therefore, the easy switch between light and dark mode is a must.
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg='4' xs='12' className='text-start py-5 my-5 align-self-center'>
                            <div className='prototype_title'>
                                <div className='workBoldItalic pb-2 mb-2'>
                                    Prototype
                                </div>
                                <div className='workBold3 pb-4 mb-4'>
                                    Senior-friendly features
                                </div>
                            </div>
                        </Col>
                        <Col lg='4' xs='12' className='pt-5 my-5'>
                            <div className='w-75 mx-auto'>
                                <SingleSource src={`/img/${workItem.title.toLocaleLowerCase()}/prototype_1.gif`} />
                                <div className='pt-4'>
                                    <span className='workBold'>Accessible & dark modes</span>
                                    <br></br>
                                    Large font sizes and high contrast help users with presbyopia. And dark mode for users with cataracts
                                </div>
                            </div>
                        </Col>
                        <Col lg='4' xs='12' className='pt-5 my-5'>
                            <div className='w-75 mx-auto'>
                                <SingleSource src={`/img/${workItem.title.toLocaleLowerCase()}/prototype_2.gif`} />
                                <div className='pt-4'>
                                    <span className='workBold'>Autosave to cloud album</span>
                                    <br></br>
                                    Some seniors don't know how to save photos to local album, some have problems locating the saved images 
                                </div>
                            </div>
                        </Col>
                        <Col lg='4' xs='12' className='pt-5 my-5'>
                            <div className='w-75 mx-auto'>
                                <SingleSource src={`/img/${workItem.title.toLocaleLowerCase()}/prototype_3.gif`} />
                                <div className='pt-4'>
                                    <span className='workBold'>Image save to local prompt</span>
                                    <br></br>
                                    Some seniors access the internet only when wi-fi is available.
                                </div>
                            </div>
                        </Col>
                        <Col lg='4' xs='12' className='pt-5 my-5'>
                            <div className='w-75 mx-auto'>
                                <SingleSource src={`/img/${workItem.title.toLocaleLowerCase()}/prototype_4.gif`} />
                                <div className='pt-4'>
                                    <span className='workBold'>Calendar countdown</span>
                                    <br></br>
                                    Countdowns with recurring options
                                </div>
                            </div>
                        </Col>
                        <Col lg='4' xs='12' className='pt-5 my-5'>
                            <div className='w-75 mx-auto'>
                                <SingleSource src={`/img/${workItem.title.toLocaleLowerCase()}/prototype_5.gif`} />
                                <div className='pt-4'>
                                    <span className='workBold'>Real contact info</span>
                                    <br></br>
                                    Real name, relationship & birthday
                                </div>
                            </div>
                        </Col>
                        <Col lg='4' xs='12' className='pt-5 my-5'>
                            <div className='w-75 mx-auto'>
                                <SingleSource src={`/img/${workItem.title.toLocaleLowerCase()}/prototype_6.gif`} />
                                <div className='pt-4'>
                                    <span className='workBold'>Add Post</span>
                                    <br></br>
                                    Easy sharing
                                </div>
                            </div>
                        </Col>
                        <Col lg='4' xs='12' className='pt-5 my-5'>
                            <div className='w-75 mx-auto'>
                                <SingleSource src={`/img/${workItem.title.toLocaleLowerCase()}/prototype_7.gif`} />
                                <div className='pt-4'>
                                    <span className='workBold'>One-click login</span>
                                    <br></br>
                                    Login with WeChat
                                </div>
                            </div>
                        </Col>
                        <Col lg='4' xs='12' className='text-center py-5 my-5 align-self-center'>
                            <div className='w-75 mx-auto'>
                                Beyond those, I adopted text labels in case seniors find the icons unfamiliar.
                                <br></br><br></br>
                                I also left adequate space between the tappable areas to prevent users from accidentally selecting the wrong items. 
                            </div>
                        </Col>
                    </Row>
                    <Row className='py-5 my-5' id='prototype'>
                        <Col sm='12' className='text-center pt-5 mt-5'>
                            <span className='workBold'>Clickable prototype (light & dark mode)</span>
                        </Col>
                        <Col lg sm='12' className='text-start py-5'>
                            <iframe width="100%" height="800px" title='white' src="https://www.figma.com/embed?embed_host=share&url=https://www.figma.com/proto/gKgh6iyo48ihy56x3qa7bL/Bonding?page-id=0%3A1&node-id=41%3A4103&viewport=575%2C190%2C0.1&scaling=scale-down&hide-ui=1" allowFullscreen></iframe>
                        </Col>
                        <Col lg sm='12' className='text-start py-5'>
                            <iframe width="100%" height="800px" title='dark' src="https://www.figma.com/embed?embed_host=share&url=https://www.figma.com/proto/gKgh6iyo48ihy56x3qa7bL/Bonding?page-id=152%3A8104&node-id=152%3A8494&viewport=-4191%2C1344%2C1.65&scaling=scale-down&hide-ui=1" allowFullscreen></iframe>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section id="section-10">
                <Container fluid className='py-5 altbg2' id='style_guide'>
                    <Container className='py-5 work'>
                        <Row className='py-5'>
                            <Col className='text-start'>
                                <SingleSource src={`/img/${workItem.title.toLocaleLowerCase()}/ds.png`} />
                            </Col>
                        </Row>
                        <Row className='py-5'>
                            <Col className='text-start'>
                                <SingleSource src={`/img/${workItem.title.toLocaleLowerCase()}/ds_2.png`} />
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </section>
            <section>
                <Container className='work py-5 mt-5' id='impact'>
                    <Row className='py-5'>
                        <Col className='text-start py-5' >
                            <div className='workBoldItalic pb-2 mb-2'>
                                Validation & impact
                            </div>
                            <div className='workBold3 pb-4 mb-4'>
                                53 NPS! We were thrilled to see how much users adore Bonding 
                            </div>
                            <div className='pb-4 mb-4'>
                                To validate, I tested the hi-fi prototype with the same 8 participants using the same prompts. The result showed 
                                <br></br>
                                •	All participants were able to complete the prompts. 
                                <br></br>
                                •	All senior participants reported the app was easy to navigate. 
                                <br></br>
                                •	7 out of 8 participants found the product meaningful and look forward to using it.
                                Seniors use a limited number of apps. To ensure the design is understandable, I must adopt the interfaces they are familiar with. And the answer is obvious - use the WeChat UI Kit.
                                <br></br><br></br>
                                Convinced by the results, I presented the design to other stakeholders. After some minor adjustments to the interface, we got the greenlight from the executives.
                                <br></br><br></br>
                                Since launch, we received both positive and negative feedback. Users responded well to features. Negative feedback largely relates to the technical limitation of mini programs. 6 months after the lauch, 
                            </div>
                            <div className='text-center workBold'>
                                <p>53 NPS (Net Promoter Score)</p>
                                <p>Average 5000+  MAU (month active user)</p>
                                <p>Selected as a Top 100 social network mini program</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section id="section-10">
                <Container fluid className='py-5 altbg2'>
                    <Container className='py-5 work'>
                        <Row>
                            <Col className='text-start'>
                                <div className='workBoldItalic pb-4 mb-4'>
                                    Design principles that guided my decisions
                                </div>
                                <div className='workBold2 d-flex'>
                                    <img alt='' src={`/img/${workItem.title.toLocaleLowerCase()}/icon_1.png`} />&nbsp;Inclusive design for older users
                                </div>
                                <div className='pb-4 mb-4'>
                                    Accessible mode and intuitive UI. Create a digital environment that resembles seniors' frequently used apps.
                                </div>
                                <div className='workBold2 d-flex'>
                                    <img alt='' src={`/img/${workItem.title.toLocaleLowerCase()}/icon_2.png`} />&nbsp;Safe social network
                                </div>
                                <div className='pb-4 mb-4'>
                                    Supply a secured and intimate environment to encourage the willingness to share. 
                                </div>
                                <div className='workBold2 d-flex'>
                                    <img alt='' src={`/img/${workItem.title.toLocaleLowerCase()}/icon_3.png`} />&nbsp;Next billion users
                                </div>
                                <div className='pb-4 mb-4'>
                                    Built for spotty internet connections and first-time internet users.
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </section>
            <section>
                <Container className='work py-5 mt-5' id='next_step'>
                    <Row>
                        <Col className='text-start py-5' >
                            <div className='workBoldItalic'>
                                Next Step
                            </div>
                            <div className='pb-4 mb-4'>
                                The comprehensive business model includes advertisements and e-commerce that focus on senior products. The functions will be introduced once acquiring the benchmark number of users.
                            </div>
                        </Col>
                    </Row>
                    <Row id='takeaways'>
                        <Col className='text-start py-5' >
                            <div className='workBoldItalic'>
                                Takeaways
                            </div>
                            <div className='workBold3'>
                                Inclusive design, mind the mental model gaps and cognitive loads
                            </div>
                            <div className='py-5'>
                                I learned so much from the project:
                                <br></br>
                                1. Users mental models of the UI are more deficient compared to designers. 
                                As a designer, I should <span className='workBold2'>take better use of cognitive loads</span> by conforming design to users' mental models. And <span className='workBold2'>improve users' mental models</span> by providing icon text label and help documents. 
                                <br></br>
                                <br></br>
                                2. <span className='workBold2'>It's fine to break rules</span>. We didn't follow the “do not interrupt” rule during the usability study, as senior users do need some guidance. We considered it completely fine because the case in real life is: seniors usually get help from family or friends when they explore new digital products. 
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='text-start py-5' >
                            <div className='workBoldItalic'>
                                Afterthoughts
                            </div>
                            <div>
                                Designing the mini-program has been a challenging and rewarding journey. It was clear from the onset we 
                                will face obstacles in seniors' digital literacy. We spent quite some time observing seniors' digital behaviors 
                                and their perceptions of digital products. The job of my role was to synthesize the findings into design and 
                                come up with a workable solution. The impect convinced us that we did a good job. I'm so proud and privileged to be a part of this social good project, and truly glad my design can make positive impact.
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section>
                <Container fluid className='work py-5 mt-5' id='iteration'>
                    <img alt='' width="100%" src={`/img/${workItem.title.toLocaleLowerCase()}/bottom.jpg`} />
                </Container>
            </section>
            <Container className='work workFooter'>
                <WorkFooter workPrevious={workItem.workPrevious} workNext={workItem.workNext} />
            </Container>
            <Context workPrevious={workItem.workPrevious} workNext={workItem.workNext} contextItems={workItem.contextItems} />
        </>
    );
  }