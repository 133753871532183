import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import React from 'react';
import {SingleSource,WorkItems} from '../utility';
import WorkFooter from '../components/workfooter';
import Context from '../components/context';

export default function WorkPage() {
    const workItem = WorkItems();

    return (
        <div className='work-fontColor2'>
            <Container className='pt-5 mt-5 work'>
                <div className='subTitle text-start'>Web application</div>
                <h1 className='mainTitle text-start py-2'>{workItem.title}</h1>
                <div className='mainDes text-start pb-4'>
                Over 20 years ago, a transportation agency created a Windows Form Application to track contracts, monitor costs, and manage change orders.&nbsp;
                    <span className='workBold'>The challenge is to build a consistent and enhanced replacement.</span>.
                </div>
                <Row className='py-5 text-start'>
                    <Col lg='3' sm='12' className='py-3'>
                        <div className='workBold2'>
                            Client
                        </div>
                        <div>
                            A major northeast transportation agency
                        </div>
                    </Col>
                    <Col lg='2' sm='12' className='py-3'>
                        <div className='workBold2'>
                            Role
                        </div>
                        <div>
                            UX Desinger
                        </div>
                    </Col>
                    <Col lg='2' sm='12' className='py-3'>
                        <div className='workBold2'>
                            Time
                        </div>
                        <div>
                            2020 Phase 1
                            <br></br>
                            2021 Phase 2
                        </div>
                    </Col>
                    <Col lg='5' sm='12' className='py-3'>
                        <div className='workBold2'>
                            Team
                        </div>
                        <div>
                            1 Project Manager, 3 Engineers, 1 UX Designer,
                            <br></br>
                            1 Business Analysts, and 1 Quality Assurance
                        </div>
                    </Col>
                </Row>
            </Container>
            <section>
                <Container fluid className='pb-4'>
                    <img alt=''width="100%" src={`/img/${workItem.title.toLocaleLowerCase()}/cover.jpg`}/>
                    <Container className='work pt-4 nda'>
                        *To comply with my non-disclosure agreement, I have omitted and obfuscated confidential information in this case study. 
                    </Container>
                </Container>
            </section>
            <section>
                <Container className='work'>
                    <div className='mainbg'>
                        <Row>
                            <Col lg sm='12' className='text-start py-5 my-5'>
                                <div className='workBoldItalic pt-5' id="context">
                                    Context & Challenge
                                </div>
                                <div className='workBold3 pb-5 mb-3 w-75'>
                                    An information management system needs to be replaced due to a technology's end of life
                                </div>
                                <div className='workBold2'>
                                    Goals & Metrics
                                </div>
                                <div>
                                    •	Increase work efficiency.
                                    <br></br>
                                    •	Ease learning curve.
                                </div>
                            </Col>
                            <Col lg sm='12' className='text-start py-5 my-5'>
                                <div className='text-start pt-5'>
                                    &nbsp;
                                </div>
                                <div className='pb-3 mb-5'>
                                    WT (acronym) system is a Visual FoxPro application. The application is deployed on the agency's network and installed on users' PCs. The application is available to all staff who have network connectivity as a client application. There are over 1,000 users and approximately 150 users have editing rights. It has been determined that the existing WT system will be replaced with a new custom information system because Microsoft no longer supports Visual FoxPro.
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
            <section>
                <Container fluid>
                    <img alt=''width="100%" src={`/img/${workItem.title.toLocaleLowerCase()}/breaker.png`}/>
                </Container>
            </section>
            <section>
                <Container className='work'>
                    <Row>
                        <Col lg sm='12' className='text-start py-5 my-5'>
                            <div className='workItalic pt-5'>
                                Research
                            </div>
                            <div className='workBold3 pb-5 mb-5 w-75'>
                                Be in users' shoes and talk to them
                            </div>
                            <div className='pb-5 mb-5'>
                                We kicked off the project by identifying the rewrite requirements and examining the legacy system. Having the functional specifications figured out, we concluded 8 workflows and 32 use cases.
                            </div>
                            <div>
                                Information architecture
                            </div>
                            <div className='pb-5 mb-5'>
                                <SingleSource alt='' src={`/img/${workItem.title.toLocaleLowerCase()}/ia.png`} />
                            </div>
                            <div>
                                Workflow example
                            </div>
                            <div className='pb-5 mb-5'>
                                <SingleSource alt='' src={`/img/${workItem.title.toLocaleLowerCase()}/workflow.png`} />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section>
                <Container fluid className='work altbg4 py-5 mt-5'>
                    <Container className='altbg4Font'>
                        <Row>
                            <Col lg sm='12' className='text-start py-5 my-5 '>
                                <div className='workItalic pt-5' id="usability_study">
                                    Usability study
                                </div>
                                <div className='pb-5 w-75'>
                                    The IT department shared the error log, which was supposed to be used as optimization guidance. However, it later occurred to me that some existing users were trained and accustomed to the system, so the report was biased. The employee turnover rate is actually high because of its industrial nature. Therefore, to deliver an effective product to both existing and new users, the BA and I tried out 26 use cases from new users' perspectives and came up with a list of possible obstacles and frustrations.
                                </div>
                                <div className='workItalic' id="heuristic_evaluation">
                                    Heuristic evaluation
                                </div>
                                <div className='pb-5 mb-5 w-75'>
                                Meanwhile, BA and I examined the interfaces according to usability heuristics.
                                </div>
                            </Col>
                            <Col lg sm='12' className='text-start py-5 my-5'>
                                <div className='workItalic pt-5' id="interviews">
                                    Interviews
                                </div>
                                <div className='pb-5 mb-5 w-75'>
                                    Among the 3 user groups, there are only a few system admins. Also, because IT staff are quick learners of digital products, we decided to focus on serving the other 2 user types: the 150+ users with editing rights (contract owners), and the 850+ read-only users. We interviewed 4 representative users and hoped to find out: 
                                    <br></br>
                                    <br></br>
                                    1.	What made the experience positive/negative.
                                    <br></br>
                                    2.	The spaces for improvements.
                                    <br></br>
                                    3.	Functional and visual preferences.
                                    <br></br>
                                    <br></br>
                                    Following, we had a session with system admins to verify the issues, and to gain insights from the support team.
                                    <br></br>
                                    <br></br>
                                    <a className='workText3' target='_blank' rel="noreferrer" href={`/img/${workItem.title.toLocaleLowerCase()}/WT UX Research Plans.pdf`}>WT research plans</a>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    <Row className='mx-0 pb-5'>
                        <Col></Col>
                        <Col lg='10' className='text-center py-5 mainbg roundCol' id="personas">
                            <Container className='work'>
                                <Row>
                                    <Col lg='12' md='12' sm='12' className='text-start pt-5 mt-5'>
                                        <div className='workBold3 pb-2 mb-2'>
                                            Office & Field Workers; Long-time User & Newcomer
                                        </div>
                                        <div className='pb-2 mb-2'>
                                            Based on the understanding, I created two personas to guide and judge my design decisions. 
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg='12' md='12' sm='12' className='pb-5 mb-5 lgImg'>
                                        <div className='overlapimg'>
                                            <SingleSource src={`/img/${workItem.title.toLocaleLowerCase()}/personaandrew.png`} />
                                        </div>
                                        <div className='overlapimg overlapimg-right'>
                                            <SingleSource src={`/img/${workItem.title.toLocaleLowerCase()}/personaanna.png`} />
                                        </div>
                                    </Col>
                                    <Col lg='12' md='12' sm='12' className='pb-5 mb-5 smImg'>
                                        <div className='w-75 mx-auto pb-5 mb-5'>
                                            <SingleSource src={`/img/${workItem.title.toLocaleLowerCase()}/personaandrew.png`} />
                                        </div>
                                        <div className='w-75 mx-auto'>
                                            <SingleSource src={`/img/${workItem.title.toLocaleLowerCase()}/personaanna.png`} />
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                        <Col></Col>
                    </Row>
                </Container>
            </section>
            <section>
                <Container className='work py-5 my-5' id='pain_points'>
                    <div className='py-5'>
                        Next, the project manager and the lead developer joined, and as a team, we categorized 
                        <div className="workBoldItalic">3 types of pain points.</div>
                    </div>
                    <div className='pb-5'>
                        <img alt='' style={{'width':'100%'}} src={`/img/${workItem.title.toLocaleLowerCase()}/pain_points.png`} />
                    </div>
                </Container>
            </section>
            <section>
                <Container fluid className='work altbg4 py-5 mt-5' id='solution_1'>
                    <Row className='mx-0 py-5'>
                        <Col></Col>
                        <Col lg='10' className='text-start py-5 mt-5 mainbg roundCol'>
                            <Container>
                                <div className='workItalic pt-5 mt-5'>
                                    1.	Solutions to psychological issues
                                </div>
                                <div className="workBold3 pb-5 mb-5">
                                    Functional consistency with a familiar feeling
                                </div>
                                <div className='pb-2 mb-2'>
                                    It was clear from the onset we would face a problem - users hate change. Migrating from a desktop to a web app, changes are inevitable.  Based on the personas, I sketched multiple options to see how to optimize the user experience by initiating minimal changes to . We categorized tasks into 3 types.
                                    <br></br>
                                    <br></br>
                                    3 approaches to minimise changes
                                </div>
                                <div className='pb-5 mb-5'>
                                    <img alt='' style={{'width':'100%'}} src={`/img/${workItem.title.toLocaleLowerCase()}/solution.png`} />
                                </div>
                            </Container>
                        </Col>
                        <Col></Col>
                    </Row>
                    <Container className='altbg4Font text-start py-5' id='solution_2'>
                        <div className='workItalic'>
                            2.	Solutions to productivity pain points
                        </div>
                        <div className="workBold3 pb-5 mb-5">
                            Web app renders cross-platform compatibility
                        </div>
                        <div className='pb-2 mb-2'>
                            A considerable number of staff work in fields where they can't access the system. What they do is bring the printouts which have to be prepared ahead of time and aren't eco-friendly. 
                            <br></br>
                            <br></br>
                            To respond, the dev team opted for a web application. Web applications can run on multiple platforms regardless of the operating system or device, and it's ideal where bandwidth is limited. The decision boosted the field staff's work efficiencies and cut the workload of the IT department enormously.   
                        </div>
                    </Container>
                </Container>
            </section>
            <section>
                <Container className='work py-5 mt-5 text-start' id='solution_3'>
                    <div className='pb-4 mb-4'>
                        Confirming the product type, I sketched out the paper wireframes and flushed out the hi-fi prototypes after digitally rendering representative frames and modules. We conducted cognitive walkthroughs before each handoff.
                    </div>
                    <div className='pb-2 mb-2'>
                        Paper wireframe examples
                    </div>
                    <Row className='mx-0 pb-4 mb-4'>
                        <Col lg xs='12'>
                            <SingleSource src={`/img/${workItem.title.toLocaleLowerCase()}/wireframe_1.png`} />
                        </Col>
                        <Col lg xs='12'>
                            <SingleSource src={`/img/${workItem.title.toLocaleLowerCase()}/wireframe_2.png`} />
                        </Col>
                        <Col lg xs='12'>
                            <SingleSource src={`/img/${workItem.title.toLocaleLowerCase()}/wireframe_3.png`} />
                        </Col>
                        <Col lg xs='12'>
                            <SingleSource src={`/img/${workItem.title.toLocaleLowerCase()}/wireframe_4.png`} />
                        </Col>
                        <Col lg xs='12'>
                            <SingleSource src={`/img/${workItem.title.toLocaleLowerCase()}/wireframe_5.png`} />
                        </Col>
                    </Row>
                    <div className='pb-2 mb-2'>
                        Wireframe examples
                    </div>
                    <Row className='mx-0 pb-4 mb-4'>
                        <Col lg xs='12'>
                            <SingleSource src={`/img/${workItem.title.toLocaleLowerCase()}/wireframe_6.png`} />
                        </Col>
                        <Col lg xs='12'>
                            <SingleSource src={`/img/${workItem.title.toLocaleLowerCase()}/wireframe_7.png`} />
                        </Col>
                        <Col lg xs='12'>
                            <SingleSource src={`/img/${workItem.title.toLocaleLowerCase()}/wireframe_8.png`} />
                        </Col>
                        <Col lg xs='12'>
                            <SingleSource src={`/img/${workItem.title.toLocaleLowerCase()}/wireframe_9.png`} />
                        </Col>
                    </Row>
                </Container>
            </section>
            <section>
                <Container fluid className='work altbg4 py-5 mt-5'>
                    <Row className='mx-0'>
                        <Col></Col>
                        <Col lg='10' className='text-start py-5 my-5 mainbg roundCol'>
                            <Container>
                            <div className='pb-2 mb-2'>
                                Final workstreams
                            </div>
                            <Row className='mx-0 pb-4 mb-4'>
                                <Col>
                                    <SingleSource src={`/img/${workItem.title.toLocaleLowerCase()}/wireframe_10.png`} />
                                </Col>
                            </Row>
                            </Container>
                        </Col>
                        <Col></Col>
                    </Row>
                </Container>
            </section>
            <section>
                <Container className='work py-5 mt-5 text-start' id='solution_3'>
                    <div className='workItalic'>
                        3.	Solutions to usability issues
                    </div>
                    <div className="pb-5 mb-5">
                        <span className='workItalic'>3.1&nbsp;</span>
                        <span className="workBold3">Visibility of system status
                        </span>
                    </div>
                    <div className='pb-2 mb-2'>
                        In some sections, the legacy system has multiple layers of popup windows, and users often lose track of their status. In response, I introduced breadcrumbs.
                        <br></br>
                        <br></br>
                    </div>
                    <div className='pb-5 mb-5'>
                        <SingleSource src={`/img/${workItem.title.toLocaleLowerCase()}/solution_3_1.png`} />
                    </div>
                </Container>
            </section>
            <section>
                <Container fluid className='work altbg4 py-5 mt-5'>
                    <Row className='mx-0'>
                        <Col></Col>
                        <Col lg='10' className='text-start py-5 my-5 mainbg roundCol'>
                            <Container className='py-5 mt-5'>
                                <Row>
                                    <Col>
                                        <div className="pb-2 mb-2">
                                            <span className='workItalic'>3.2&nbsp;</span>
                                            <span className="workBold3">Error prevention & recovery
                                            </span>
                                        </div>
                                        <div className="py-3 my-3">
                                            <span className='workBoldItalic'>3.2.1 Visual treatment for mistakes</span>
                                        </div>
                                        <div className='pb-2 mb-2'>
                                            Each personnel in different projects has a different view or editing rights. There are so many “not so gray” grayed-out functions in the legacy system. Users often misunderstand them to mean the function is available. To eliminate error-prone conditions, I adopted stronger color contrast. 
                                            <br></br>
                                            <br></br>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className='lgImg'>
                                    <Col>
                                        <div className='pb-5 mb-5'>
                                            <SingleSource src={`/img/${workItem.title.toLocaleLowerCase()}/solution_3_2_1.png`} />
                                        </div>
                                    </Col>
                                </Row>
                                <Row className='smImg'>
                                    <Col>
                                        <div className='pb-5 mb-5'>
                                            <img alt='' style={{'width':'100%'}} src={`/img/${workItem.title.toLocaleLowerCase()}/solution_3_2_1_m.png`} />
                                        </div>
                                    </Col>
                                </Row>
                                <Row className='border-bottom'>
                                    <Col lg xs='12' className='pb-5 mb-5'>
                                        <SingleSource src={`/img/${workItem.title.toLocaleLowerCase()}/solution_3_2_3.png`} />
                                    </Col>
                                    <Col lg xs='12' className="pb-2 px-5 mb-2">
                                        System admins sometimes must get involved as certain entries can only be changed from the backend. In the new system, we applied
                                        &nbsp;<span className='greenFont'>warning colors on unrecoverable commands</span>&nbsp;
                                        and introduced conditional undo functions. 
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg xs='12' className='pt-5 mt-5'>
                                        <div className="pb-3 mb-3">
                                            <span className='workBoldItalic'>3.2.2 Data validation for slip</span>
                                        </div>
                                        <div className='pb-3 mb-3'>
                                        Each contract/ project has an authorized budget, no single entry nor sum-up should exceed the amount. However, users sometimes accidentally input wrong numbers, which leads to extra work in monthly reporting. To address this, we created a 
                                        <span className='greenFont'> data validation mechanism </span>
                                        based on business logics. In the new system, a warning message will be present when such slips happen.
                                            <br></br>
                                            <br></br>
                                        </div>                                        
                                    </Col>
                                    <Col lg xs='12' className="pt-5 mt-5">
                                        <SingleSource src={`/img/${workItem.title.toLocaleLowerCase()}/solution_3_2_4.png`} />
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                        <Col></Col>
                    </Row>
                </Container>
            </section>
            <section>
                <Container className='altbg4Font text-start py-5 my-5 work'>
                    <div className="pb-5 mb-5">
                        <span className='workItalic'>3.3&nbsp;</span><span className="workBold3">Enhanced error recognition & diagnose</span>
                    </div>
                    <div className='pb-4 mb-4'>
                        In the legacy system, users get the warning but can't see what went wrong.  To help users better diagnose, we adopted combinations of visual treatments and error messages.
                    </div>
                    <div className='pb-2 mb-2'>
                        <SingleSource src={`/img/${workItem.title.toLocaleLowerCase()}/solution_3_3.png`} />
                    </div>
                </Container>
            </section>
            <section>
                <Container fluid className='work altbg4 py-5 mt-5' id='improvements'>
                    <Row className='mx-0'>
                        <Col></Col>
                        <Col lg='10' className='text-start py-5 my-5 mainbg roundCol'>
                            <Container className='py-5 mt-5'>
                                <Row>
                                    <Col>
                                        <div className="pb-4 mb-4 workItalic">
                                            4. Other Improvements:
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs='12' lg>
                                        <div className='pb-3 mb-3 mx-auto w-75'>
                                            <SingleSource src={`/img/${workItem.title.toLocaleLowerCase()}/solution_4.png`} />
                                        </div>
                                    </Col>
                                    <Col className='border-left'>
                                        <div className='pb-3 mb-3 mx-auto w-75'>
                                            <SingleSource src={`/img/${workItem.title.toLocaleLowerCase()}/solution_4_1.png`} />
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                        <Col></Col>
                    </Row>
                </Container>
            </section>
            <section>
                <Container className='altbg4Font text-start py-5 my-5 work'>
                    <div className='workItalic pt-5' id="iteration">
                        Iterations
                    </div>
                    <div className='workBold3 pb-2 mb-2'>
                        Contextual UI, design for unique needs
                    </div>
                    <div className='pb-2 mb-2'>
                        Along the process, we conducted rounds of cognitive walkthroughs using personas and usability studies. Below are some iterations.
                    </div>
                    <Row>
                        <Col xs='12' lg className='py-3 my-3'>
                            <div className='w-75 mx-auto'>
                                In my design version No.1, I grayed out unclickable buttons like in the legacy system. However, we found that due to the lighting environment, field workers often misunderstand especially when both buttons are grayed out. 
                            </div>
                            <Row>
                                <Col></Col>
                                <Col className='py-2 my-2'>
                                    <div className='mx-auto'>
                                        <SingleSource src={`/img/${workItem.title.toLocaleLowerCase()}/button1.png`} />
                                    </div>                                    
                                </Col>
                                <Col className='py-2 my-2'>
                                    <div className='mx-auto'>
                                        <SingleSource src={`/img/${workItem.title.toLocaleLowerCase()}/button2.png`} />
                                    </div>    
                                </Col>
                                <Col></Col>
                            </Row>
                        </Col>
                        <Col className='py-3 my-3'>
                            <div className='mx-auto w-75'>
                                <SingleSource src={`/img/${workItem.title.toLocaleLowerCase()}/button3.png`} />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='py-2 my-2 lgImg'>
                            <SingleSource src={`/img/${workItem.title.toLocaleLowerCase()}/version_1.png`} />
                        </Col>
                        <Col className='py-2 my-2 smImg'>
                            <SingleSource src={`/img/${workItem.title.toLocaleLowerCase()}/version_1_m.png`} />
                        </Col>
                    </Row>
                    <Row>
                        <Col className='py-2 my-2 lgImg'>
                            <SingleSource src={`/img/${workItem.title.toLocaleLowerCase()}/version_2.png`} />
                        </Col>
                        <Col className='py-2 my-2 smImg'>
                            <SingleSource src={`/img/${workItem.title.toLocaleLowerCase()}/version_2_m.png`} />
                        </Col>
                    </Row>
                </Container>
            </section>
            <section>
                <Container fluid className='work altbg4 py-5 mt-5'>
                    <Row className='mx-0'>
                        <Col></Col>
                        <Col lg='10' className='text-start py-5 my-5 mainbg roundCol'>
                            <Container className='py-5 mt-5'>
                                <Row>
                                    <Col>
                                        <div className="pb-2 mb-2">
                                            In version 1, the "Prior" and "Next" were removed, as we opted for the list view. Yet we got complaints because removing the buttons made it hard for users to skim between contracts.
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='py-3 my-3'>
                                        <SingleSource src={`/img/${workItem.title.toLocaleLowerCase()}/version_3.png`} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='py-3 my-3'>
                                        <SingleSource src={`/img/${workItem.title.toLocaleLowerCase()}/version_4.png`} />
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                        <Col></Col>
                    </Row>
                </Container>
            </section>
            <section>
                <Container className='altbg4Font text-start py-5 my-5 work'>
                    <div className='workItalic pt-5' id="impact">
                        The impact
                    </div>
                    <div className='workBold3 pb-4 mb-4'>
                        Data proven positive impact
                    </div>
                    <div className='pb-5 mb-5'>
                        Months after handing over, we followed up with the client and were glad to find that:
                        <br></br>
                        <br></br>
                        •	Supporting <span className='workBold'>tickets opening rate decreased by 32%</span>.
                        <br></br>
                        •	Average monthly <span className='workBold'>reporting preparation time decreased by 67%</span>.
                        <br></br>
                        •	Because field staff no longer need to use printouts, <span className='workBold'>printing counts decreased by 59%</span>.
                    </div>
                    <div className='workItalic pt-5' id="conclusion">
                        Conclusion & Takeaway
                    </div>
                    <div className='workBold3 pb-4 mb-4'>
                        The importance of consistency and adaptability
                    </div>
                    <div className='pb-2 mb-2'>
                        Rewriting a system with a 1000+ user base is always a big responsibility. In agile, we worked with clients closely and successfully brushed up on the interface while keeping users comfortable with familiar interactions.
                        <span className='workBold'> I learned that for existing users, the best user experience is consistent. I also got a deeper understanding of “you ≠ user” from iterations.
                        </span>
                    </div>
                </Container>
            </section>
            <section>
                <Container fluid className='work py-5 mt-5' id='iteration'>
                    <img alt='' width="100%" src={`/img/${workItem.title.toLocaleLowerCase()}/bottom.jpg`} />
                </Container>
            </section>
            <Container className='work workFooter'>
                <WorkFooter workPrevious={workItem.workPrevious} workNext={workItem.workNext} />
            </Container>
            <Context workPrevious={workItem.workPrevious} workNext={workItem.workNext} contextItems={workItem.contextItems} />
        </div>
    );
  }