import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import React from 'react';
import {SingleSource} from '../utility';

export default function About() {
    return (
      <>
        <div className='lgImg'>
          <Container fluid className='about-cover' style={{background:"url('img/about/cover.jpg') no-repeat",backgroundSize: "cover"}}>
            <Container>
              <Row className='align-items-center'>
                <Col lg='6' className='text-start'>
                  <div className='about-cover-text py-5'>
                    <div className='title py-5 w-75'>
                      It's so great to e-meet you : )
                      <br></br><br></br>
                      I triangulate my <span className='workBold'>problem-solving</span> mindset, <span className='workBold'>data-driven</span> approach, and <span className='workBold'>business acumen</span> to provide impactful user-centric designs that align with business objectives.  
                    </div>
                    <div className='resumelink py-3'>
                      <a href='/img/about/AmberSChenResume.pdf' target="_blank">Resume</a>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </Container>
        </div>
        <div className='smImg'>
          <Container fluid className='about-cover' style={{background:"url('img/about/cover_m.jpg') no-repeat center",backgroundSize: "cover"}}>
            <Container>
              <Row className='align-items-end'>
                <Col lg='6' className='text-start'>
                  <div className='about-cover-text py-5'>
                  <div className='title py-5 w-75'>
                      It's so great to e-meet you : )
                      <br></br><br></br>
                      I triangulate my <span className='workBold'>problem-solving</span> mindset, <span className='workBold'>data-driven</span> approach, and <span className='workBold'>business acumen</span> to provide impactful user-centric designs that align with business objectives.  
                    </div>
                    <div className='resumelink py-3'>
                      <a href='/img/about/AmberSChenResume.pdf' target="_blank">Resume</a>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </Container>
        </div>
        <Container className='about-text text-start work'>
          <Row className='py-5 px-4'>
            <Col>
              I'm valued for my sense of ownership and enthusiasm, and I intend to produce work that every stakeholder is proud of. My passion lies in the execution of an idea. I love diving into why and how people use things and furthering the insights in my work. The world constantly evolves, and users' expectations change over time. So, to cater, I am forever expanding my skillset.
              <br></br><br></br>
              I share stories about <span className='workBold'>User Experience, Consumer Experience, Digital Literacy</span> and <span className='workBold'>Asian Identity</span> on television, educational facilities, podcasts, and radio.  
              <br></br><br></br>
              I'm a huge Lego fan, but I also enjoy going out and capturing the beauty and weirdness of nature. When time allows, I bake and lead painting sessions. 
            </Col>
          </Row>
          <Row className='py-5 my-5 align-items-center'>
            <Col lg='3'>
             <span className='workBold4'>Talks I gave</span>
             <br></br><br></br>
             <span className='work'>UX, CX, Digital Transformation, Asian Identity and more</span>
            </Col>
            <Col>
              <Row className='py-2'>
                <Col>
                  <SingleSource alt='' src={`/img/about/talk1.jpg`} />
                </Col>
                <Col>
                  <SingleSource alt='' src={`/img/about/talk2.jpg`} />
                </Col>
              </Row>
              <Row className='py-2'>
                <Col>
                  <SingleSource alt='' src={`/img/about/talk3.jpg`} />
                </Col>
                <Col>
                  <SingleSource alt='' src={`/img/about/talk4.jpg`} />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className='py-5 my-5 align-items-center'>
            <Col lg='3' className='smImg'>
              <span className='workBold4'>Cakes I made</span>
              <br></br><br></br>
              <span className='work'>Fondant & buttercream</span>
            </Col>
            <Col>
              <Row className='py-2'>
                <Col lg xs='6' className='py-2'>
                  <SingleSource alt='' src={`/img/about/cake1.jpg`} />
                </Col>
                <Col lg xs='6' className='py-2'>
                  <SingleSource alt='' src={`/img/about/cake2.jpg`} />
                </Col>
                <Col lg xs='6' className='py-2'>
                  <SingleSource alt='' src={`/img/about/cake3.jpg`} />
                </Col>
                <Col lg xs='6' className='py-2'>
                  <SingleSource alt='' src={`/img/about/cake4.jpg`} />
                </Col>
              </Row>
            </Col>
            <Col lg='3' className='lgImg'>
              <span className='workBold4'>Cakes I made</span>
              <br></br><br></br>
              <span className='work'>Fondant & buttercream</span>
            </Col>
          </Row>
          <Row className='py-5 my-5 align-items-center'>
            <Col lg='3'>
              <span className='workBold4'>My Lego head paintings ;)</span>
              <br></br><br></br>
              <span className='work'>Acrylic on canvas, 22" x 28" </span>
            </Col>
            <Col>
              <Row className='py-2'>
                <Col lg xs='6' className='py-2'>
                  <SingleSource alt='' src={`/img/about/lego1.jpg`} />
                </Col>
                <Col lg xs='6' className='py-2'>
                  <SingleSource alt='' src={`/img/about/lego2.jpg`} />
                </Col>
                <Col lg xs='6' className='py-2'>
                  <SingleSource alt='' src={`/img/about/lego3.jpg`} />
                </Col>
                <Col lg xs='6' className='py-2'>
                  <SingleSource alt='' src={`/img/about/lego4.jpg`} />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className='py-5 my-5'>
            <Col className='pt-5 mt-5 about-philosophy text-center'>
              My design philosophy
            </Col>
          </Row>
          <Row>
            <Col lg xs='12' className='text-center'>
              <div className='text-center workBoldItalic'>
                Mind user's mental<br></br>model
              </div>
              <div className='text-start py-5 w-75 mx-auto'>
                <ul>
                  <li>
                  Users don't know the product as much as designers do.
                  </li>
                  <li>
                  Research and test with representative real users.
                  </li>
                  <li>
                  Improve misaligned mental models.
                  </li>
                </ul>
              </div>
            </Col>
            <Col lg xs='12' className='text-center'>
              <div className='text-center workBoldItalic'>
                Good product serves<br></br>consumer & business
              </div>
              <div className='text-start py-5 w-75 mx-auto'>
                <ul>
                  <li>
                  Define goals for both parties.
                  </li>
                  <li>
                  Compromise and reprioritize. 
                  </li>
                  <li>
                  Blend business mind in the heart but no short-sighted decision, i.e. Dark pattern.
                  </li>
                </ul>
              </div>
            </Col>
            <Col lg xs='12' className='text-center'>
              <div className='text-center workBoldItalic'>
                Uniqueness, usability<br></br>& reusability
              </div>
              <div className='text-start py-5 w-75 mx-auto'>
                <ul>
                  <li>
                  Instead of inventing, modify existing assets to render intuitive affordance & signifier.
                  </li>
                  <li>
                  Reduce system complexity.
                  </li>
                  <li>
                  improve scalability. 
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </>
    );
  }