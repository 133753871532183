import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import React from 'react';
import {SingleSource,WorkItems} from '../utility';
import WorkFooter from '../components/workfooter';
import Context from '../components/context';

export default function WorkPage() {
    const workItem = WorkItems();

    return (
        <>
            <Container className='py-5 my-5 work'>
                <div className='subTitle text-start'>Mobile & Web App Design</div>
                <h1 className='mainTitle text-start py-2'>{workItem.title}</h1>
                <div className='mainDes text-start pb-4'>
                Big chains like Starbucks and Dunkin Donuts can increase sales revenues by over 15% annually, but the average of local coffee shops is less than 8%.
                &nbsp;<span className='workBold'>The challenge is to equip local coffee shops with digital weapons for them to compete against the big chains</span>.
                </div>
                <Row className='py-5 text-start'>
                    <Col lg sm='12' className='py-3'>
                        <div className='workBold2'>
                            Client
                        </div>
                        <div>
                            Oyster Mobile Inc. 
                        </div>
                    </Col>
                    <Col lg sm='12' className='py-3'>
                        <div className='workBold2'>
                            Role
                        </div>
                        <div>
                            UX Designer
                        </div>
                    </Col>
                    <Col lg sm='12' className='py-3'>
                        <div className='workBold2'>
                            Time
                        </div>
                        <div>
                            2019 Version 1.0
                            <br></br>
                            2022 - Last iteration
                        </div>
                    </Col>
                    <Col lg sm='12' className='py-3'>
                        <div className='workBold2'>
                            Team
                        </div>
                        <div>
                            3 Developers, 1 UX Designer
                        </div>
                    </Col>
                </Row>
            </Container>
            <section id="section-2">
                <Container fluid className='pb-4'>
                    <img alt=''width="100%" src={`/img/${workItem.title.toLocaleLowerCase()}/cover.jpg`}/>
                    <Container className='work pt-4 nda'>
                        *To comply with my non-disclosure agreement, I have omitted and obfuscated confidential information in this case study. 
                    </Container>
                </Container>
            </section>
            <section>
                <Container className='py-5 my-5 work'>
                    <div className='mainbg'>
                        <Row>
                            <Col lg sm='12' className='text-start py-2'>
                                <div className='workBoldItalic pt-5' id="context">
                                    Context & Challenge
                                </div>
                                <div className='workBold3 pb-5 w-75'>
                                    Local coffee shops lose customers to big chains
                                </div>
                                <div>
                                    Download app
                                </div>
                                <div className='w-25 py-2'>
                                    <SingleSource src={`/img/${workItem.title.toLocaleLowerCase()}/qr.png`} />
                                </div>
                                <div>
                                    <a className='workText2' href='https://www.ordersipps.com/'>Check OrderSipps.com</a>
                                </div>
                            </Col>
                            <Col lg sm='12' className='text-start py-2'>
                                <div className='text-start pt-5'>
                                    &nbsp;
                                </div>
                                <div className='pb-3 mb-5'>
                                    The founders discover the business opportunities that lie in the 22 billion local coffee shops markets: their growth has been hindered because they constantly losing customers to big chains. The objective is to help local coffee shops with a full-stack digital solution. <span className='workBold2'>The final design includes a mobile app for consumers, a web app for coffee shops, and merchant devices</span>. 
                                    <br></br><br></br>
                                    <span className='workBold2'>Goals & Metrics</span>
                                    <br></br>
                                    •	Increase sales.
                                    <br></br>
                                    •	Nurture customer loyalty.
                                    <br></br>
                                    •	Improve shop staff's efficiency.
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
            <section>
                <Container fluid>
                    <img alt=''width="100%" src={`/img/${workItem.title.toLocaleLowerCase()}/breaker.png`}/>
                </Container>
            </section>
            <section>
                <Container className='py-5 my-5 work' id='consumer_app'>
                    <Row className='py-5 mt-5'>
                        <Col lg xs='12' className='text-start py-2'>
                            <div className='workBoldItalic pt-5' >
                                Consumer app design (Mobile app)
                            </div>
                            <div className='workBoldItalic pt-5' >
                                Research
                            </div>
                            <div className='workBold3'>
                                What value can we bring to consumers?
                            </div>
                            <div className='pt-5'>
                                <a className='workText2' target='_blank' rel="noreferrer" href={`/img/${workItem.title.toLocaleLowerCase()}/Oyster UX Research Plans.pdf`}>Oyster research plans</a>
                            </div>
                            <div className='workItalic pt-5' id='web_research'>
                                Secondary research
                            </div>
                            <div>
                                Trying to understand why people favor big chains, the founders and I kicked off mobile app design with secondary research. We sourced the internet, articles, forums, and Facebook groups; summarized a list of reasons, and then concluded several actionable strategies. 
                            </div>
                            <div className='workItalic pt-5 mt-3' id='interviews'>
                                Guerilla interviews
                            </div>
                            <div>
                                The goal is to help coffee shops, and to achieve that, we must involve consumers by adding value to their journeys. There’re tons of approaches, like discounts, free upgrades, and loyal programs. But which ones do consumers really want? To find answers, we did field studies to understand the contexts and needs of our target audiences, and to verify the strategies. In 2 local coffee shops (a grab-n-go and a coffee bar where people can hang out), we conducted guerilla interviews with 6 consumers, hoping to find out:
                                <br></br><br></br>
                                1.	The goal of going to a coffee shop.
                                <br></br>
                                2.	The service gap & improvement space.
                                <br></br>
                                3.	What made the experience positive/ negative.
                                <br></br>
                                4.	From the "big chain actionable strategies list", which ones matter most.
                                <br></br><br></br>
                                I created 6 sympathy maps based on interviewers' reactions and then aggregated the maps.
                                <br></br><br></br>
                            </div>
                            <div className='mt-4'>
                                Interview consumer over a nice cup of coffee
                                <SingleSource src={`/img/${workItem.title.toLocaleLowerCase()}/interview.png`} />
                            </div>
                        </Col>
                        <Col lg xs='12' className='text-center py-2' id='empathy_maps'>
                            <div className='pt-5'>
                                Aggregated empathy maps
                            </div>
                            <div className='pt-3 w-75 mx-auto'>
                                <SingleSource src={`/img/${workItem.title.toLocaleLowerCase()}/empathy_map1.png`} />
                            </div>
                            <div className='pt-3 w-75 mx-auto'>
                                <SingleSource src={`/img/${workItem.title.toLocaleLowerCase()}/empathy_map2.png`} />
                            </div>
                            <div className='pt-3 w-75 mx-auto'>
                                <SingleSource src={`/img/${workItem.title.toLocaleLowerCase()}/empathy_map3.png`} />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section>
                <Container fluid className='py-5 my-5 work'>
                    <div className='breaker altbg5'>

                    </div>
                </Container>
            </section>
            <section>
                <Container className='work' id='improve_2'>
                    <Row className='py-5 mt-5'>
                        <Col lg xs='12'className='text-start pt-5 mt-5' id="personas">
                            <div className='workItalic'>
                                Personas & journey maps
                            </div>
                            <div className='workBold pb-5 mb-5'>
                                Consumer: <span className='workBold3'>Busy Worker, Casual Drinker & Gourmet Drinker</span>
                            </div>
                            <div className='workBold pt-5 mt-5 mx-2'>
                                <SingleSource alt='' src={`/img/${workItem.title.toLocaleLowerCase()}/personaanna.png`} />
                            </div>
                            <div className='workBold pt-1 mt-1 mx-2'>
                                <SingleSource alt='' src={`/img/${workItem.title.toLocaleLowerCase()}/personaanna_map.png`} />
                            </div>
                        </Col>
                        <Col lg xs='12'className='text-start pt-5 mt-5'>
                            <div className='mx-2'>
                                <SingleSource alt='' src={`/img/${workItem.title.toLocaleLowerCase()}/personaandrew.png`} />
                            </div>
                            <div className='workBold pt-1 mt-1 mx-2'>
                                <SingleSource alt='' src={`/img/${workItem.title.toLocaleLowerCase()}/personaandrew_map.png`} />
                            </div>
                            <div className='workBold pt-5 mt-5 mx-2'>
                                <SingleSource alt='' src={`/img/${workItem.title.toLocaleLowerCase()}/personaandy.png`} />
                            </div>
                            <div className='workBold pt-1 mt-1 mx-2'>
                                <SingleSource alt='' src={`/img/${workItem.title.toLocaleLowerCase()}/personaandy_map.png`} />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section>
                <Container fluid className='altbg5 altbg5Font py-5' id='define'>
                    <div>
                        <Container className='work'>
                            <Row>
                                <Col lg='12' md='12' sm='12' className='text-start py-5' >
                                    <div className='workItalic pb-2 mb-2'>
                                        Define
                                    </div>
                                    <div className='workBold3 pb-4 mb-4'>
                                        Consumers want their coffee faster, finer and cheaper
                                    </div>
                                    <div>
                                        At this point, I was able to confirm users' biggest needs.
                                        <br></br>
                                        •	Busy workers want to save time.
                                        <br></br>
                                        •	Gourmet drinkers want the guarantee of good quality.
                                        <br></br>
                                        •	Casual drinkers want to kill time while not breaking the bank.
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </Container>
            </section>
            <section>
                <Container className='work py-5 mt-5' id='ideation'>
                    <Row className='pt-5 mt-5'>
                        <Col className='text-start py-5'>
                            <div className='workBoldItalic pb-2 mb-2'>
                                Ideation
                            </div>
                            <div className='workBold3 pb-4 mb-4'>
                                Order ahead and win rewards
                            </div>
                            <div>
                                Next, I conducted a UX workshop, invited the founders and other stakeholders to brainstorm opportunities. We ruled out 2 ideas using the matrix and dot-voted the rest. By the end of the meeting, we inclined on "Pre-order", "Reward" and "$30 for 10 cups" and planned to include "rating" as a good-to-have function. Following, we interviewed 3 random consumers to verify the solutions and excluded "$30 for 10 cups".
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg xs='12' className='pd-4 mx-2'>
                            <div>
                                Solutions for 3 personas
                            </div>
                            <SingleSource alt='' src={`/img/${workItem.title.toLocaleLowerCase()}/ideation1.png`} />
                        </Col>
                        <Col lg xs='12' className='pd-4 mx-2'>
                            <div>
                                Impact/effort matrix
                            </div>
                            <SingleSource alt='' src={`/img/${workItem.title.toLocaleLowerCase()}/ideation2.png`} />
                        </Col>
                        <Col lg xs='12' className='pd-4 mx-2'>
                            <div>
                                Dot-voting
                            </div>
                            <SingleSource alt='' src={`/img/${workItem.title.toLocaleLowerCase()}/ideation3.png`} />
                        </Col>
                    </Row>
                    <Row className='py-5 my-5'>
                        <Col lg xs='12' className='py-5 my-5 text-start' id='competitive_audit'>
                            <div className='workBoldItalic pb-2 mb-2'>
                                Competitive audit
                            </div>
                            <div className='workBold3 pb-4 mb-4'>
                                Learn from competitors
                            </div>
                            <div>
                                Understanding the product type and functionalities, we did a competitive audit. We ordered through the direct competitor Cloosiv and found some improvements spaces, like no quick access to recent orders, lack of easy sign-up, and the miss of arrival estimation.
                                <br></br><br></br>
                                Upholding Jacob's law "users spend most of their time on other sites". I constantly referred to the most popular ordering/delivery apps during the rest of my design process.
                            </div>
                        </Col>
                        <Col lg xs='12' className='pt-5 mt-5 text-center'>
                            <div className='w-75 mx-auto'>
                                Competitors matrix
                                <SingleSource alt='' src={`/img/${workItem.title.toLocaleLowerCase()}/competitors.png`} />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section>
                <Container fluid className='py-5 my-5 work'>
                    <div className='breaker altbg5'>

                    </div>
                </Container>
            </section>
            <section>
                <Container className='work' id='merchant_app'>
                    <Row className='py-5 mt-5'>
                        <Col lg='5' xs='12'className='text-start pt-5 mt-5' id="personas">
                            <div className='workItalic'>
                                Merchant app design (Web app)
                            </div>
                            <div className='workBold3 pb-3 mb-3'>
                                Hassle-free full stack solution
                            </div>
                            <div>
                                By checking with coffee shop owners, we learned staff often make mistakes when inputting orders into the systems. To eliminate error-prone situations, we decided to equip merchants with cloud printers, which automatically print drink labels when orders are accepted.
                                <br></br><br></br>
                                The main function of the merchant app is managing orders, menus, and store info. We adopted supporting functions like reporting to provide performance insights. To minimize the learning curve, I imitated the interface of Square, the most used POS system in the industry. I conducted usability studies with 3 coffee shop staff before handing the design.
                            </div>
                        </Col>
                        <Col lg='2'></Col>
                        <Col lg='5' xs='12'className='text-start pt-5 mt-5' id="personas">
                            <div className='mx-2'>
                                <SingleSource alt='' src={`/img/${workItem.title.toLocaleLowerCase()}/personaarnold.png`} />
                            </div>
                            <div className='workBold pt-1 mt-1 mx-2'>
                                <SingleSource alt='' src={`/img/${workItem.title.toLocaleLowerCase()}/personaarnold_map.png`} />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section>
                <Container fluid className='py-5 my-5 work'>
                    <div className='breaker altbg5'>

                    </div>
                </Container>
            </section>
            <section>
                <Container className='work py-5 mt-5' id='user_flow'>
                    <Row>
                        <Col className='text-center py-5'>
                            <div className='workBoldItalic pb-2 mb-2'>
                                User flows, service blueprint & information architectures
                            </div>
                            <div className='text-start'>
                                With the help of an investor, who happens to be a coffee shop owner, I framed the user flows, blueprint and IAs.
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='text-start'>
                            <div>
                                User flows
                            </div>
                            <SingleSource src={`/img/${workItem.title.toLocaleLowerCase()}/user_flow.png`} />
                            <div className='pt-5 mt-5'>
                                Service blueprint
                            </div>
                            <SingleSource src={`/img/${workItem.title.toLocaleLowerCase()}/blueprint.png`} />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg xs='12' className='text-center mx-2'>
                            <div className='pt-5 mt-5'>
                                Consumer app information architecture
                            </div>
                            <SingleSource src={`/img/${workItem.title.toLocaleLowerCase()}/consumer_ia.png`} />
                        </Col>
                        <Col lg xs='12' className='text-center mx-2'>
                            <div className='pt-5 mt-5'>
                                Merchant app information architecture
                            </div>
                            <SingleSource src={`/img/${workItem.title.toLocaleLowerCase()}/merchant_ia.png`} />
                        </Col>
                    </Row>
                </Container>
            </section>
            <section>
                <Container fluid className='py-5 my-5 work'>
                    <div className='breaker altbg5'>

                    </div>
                </Container>
            </section>
            <section>
                <Container className='py-5 my-5 work' id='wireframes'>
                    <Row className='py-5'>
                        <Col lg xs='12' className='text-start'>
                            <div className='workBoldItalic py-5' >
                                Wireframe
                            </div>
                            <div>
                                Then, I sketched a few versions of the most important pages like the shop list, menu, and order confirmation page; discussed them with founders, and rendered them into lo-fi prototypes. I then conducted usability studies with 3 prospective users and made changes. Three prominent changes are: 
                            </div>
                        </Col>
                        <Col lg xs='12' className='text-center'>
                            <div className='pt-5'>
                                Hand-drawn wireframes
                            </div>
                            <div className='pt-3 w-75 mx-auto'>
                                <SingleSource src={`/img/${workItem.title.toLocaleLowerCase()}/wireframe.png`} />
                            </div>
                        </Col>
                    </Row>
                    <Row className='py-5'>
                        <Col lg xs='12' className='text-start px-5'>
                            <SingleSource src={`/img/${workItem.title.toLocaleLowerCase()}/wireframe_1.png`} />
                        </Col>
                        <Col lg xs='12' className='text-start px-5'>
                            <SingleSource src={`/img/${workItem.title.toLocaleLowerCase()}/wireframe_2.png`} />
                        </Col>
                    </Row>
                    <Row className='py-5'>
                        <Col lg xs='12' className='text-center px-5'>
                            <div className='w-75 mx-auto'>
                                <SingleSource src={`/img/${workItem.title.toLocaleLowerCase()}/wireframe_3.png`} />
                            </div>
                        </Col>
                    </Row>
                    <Row className='py-3 mt-5'>
                        <Col lg xs='12' className='text-center px-5 workBold4'>
                            Two iterations on wireframes
                        </Col>
                    </Row>
                    <Row>
                        <Col lg xs='12'>
                            <SingleSource src={`/img/${workItem.title.toLocaleLowerCase()}/wireframe_4.png`} />
                        </Col>
                        <Col lg xs='12'>
                            <SingleSource src={`/img/${workItem.title.toLocaleLowerCase()}/wireframe_5.png`} />
                        </Col>
                        <Col lg xs='12'>
                            <SingleSource src={`/img/${workItem.title.toLocaleLowerCase()}/wireframe_6.png`} />
                        </Col>
                    </Row>
                    <Row className='py-3 mt-5'>
                        <Col lg xs='12' className='text-center px-5 workBold4'>
                            Work streams
                        </Col>
                    </Row>
                    <Row>
                        <Col lg xs='12'>
                            <SingleSource src={`/img/${workItem.title.toLocaleLowerCase()}/wireframe_7.png`} />
                        </Col>
                    </Row>
                </Container>
            </section>
            <section>
                <Container fluid className='py-5 work'>
                    <div className='breaker altbg5'>

                    </div>
                </Container>
            </section>
            <section>
                <Container className='py-5 my-5 work' id='prototype'>
                    <Row className='py-5'>
                        <Col className='text-start py-2'>
                            <div className='workBoldItalic' >
                                Prototype
                            </div>
                            <div>
                                Next, I paused for the <a className='redFontUnderline' target='_blank' rel="noopener" href={`/img/${workItem.title.toLocaleLowerCase()}/Brandbook.pdf`}>brand book</a>, and fleshed out the hi-fi prototype.
                            </div>
                            <br></br><br></br>
                            <div>
                                Video demo for mobile app 1.0, web app 1.0, and merchant device
                                <iframe width="100%" height="600px" src="https://www.youtube.com/embed/3YPq7VhwjDY" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                            </div>
                            <div>
                                *The marketing name was changed from “Oyster” to “Sipps” in December 2020. The video was made before the rebranding. 
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section>
                <Container fluid className='altbg5 altbg5Font py-5' id='iterations'>
                    <Container className='work'>
                        <Row>
                            <Col lg='12' md='12' sm='12' className='text-center py-5' >
                                <div className='workItalic pb-4 mb-4'>
                                    Consumer app Iterations
                                </div>
                                <div>
                                    Plenty of changes have been made through the years based on business decisions, user feedback and trends. The most prominent ones are:
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </section>
            <section>
                <Container className='work my-5 py-5'>
                    <Row>
                        <Col className='text-center py-5' >
                            <SingleSource src={`/img/${workItem.title.toLocaleLowerCase()}/iteration_1.png`} />
                        </Col>
                    </Row>
                    <Row>
                        <Col className='text-center py-5' >
                            <SingleSource src={`/img/${workItem.title.toLocaleLowerCase()}/iteration_2.png`} />
                        </Col>
                    </Row>
                </Container>
            </section>
            <section>
                <Container fluid className='altbg5 altbg5Font my-5 py-5' id='competitive_audit'>
                        <Container className='work'>
                            <Row>
                                <Col lg='12' md='12' sm='12' className='text-center py-5' >
                                    <div className='workItalic pb-4 mb-4'>
                                        Merchant app Iteration
                                    </div>
                                    <div>
                                        We collected feedback after launch and made iterations. Three prominent changes are:
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                </Container>
            </section>
            <section>
                <Container className='work my-5 py-5'>
                    <Row>
                        <Col lg='12' md='12' sm='12' className='text-start pt-5' >
                            <div>
                                Learning: I assumed cashiers would look at the screen when operating. but after observing how they work, I realized they only skim, they don’t actually read the text label. Therefore, in this kind of scenario, dropdown menus would only cause errors and decrease efficiency.
                            </div>
                        </Col>
                    </Row>
                    <Row className='align-items-center py-5 mb-5'>
                        <Col lg>
                            <SingleSource src={`/img/${workItem.title.toLocaleLowerCase()}/iteration_9.png`} />
                        </Col>
                    </Row>
                    <Row className='align-items-center my-5 py-5'>
                        <Col lg>
                            <div className='workBold2 text-start'>Two changes in user flow</div>
                            <SingleSource src={`/img/${workItem.title.toLocaleLowerCase()}/iteration_10.png`} />
                        </Col>
                    </Row>
                    <Row className='align-items-center pb-5'>
                        <Col lg>
                            <div className='text-start'>
                                <span className='workBold2'>Request rejection reasons</span> for the purpose of appeasing customers and collecting insights.
                            </div>
                            <SingleSource src={`/img/${workItem.title.toLocaleLowerCase()}/iteration_11.gif`} />
                        </Col>
                        <Col lg>
                            <div className='text-start'>
                                <span className='workBold2'>10 min alarm before the pick-up time</span>, as busy staff sometimes forget about mobile orders. 
                            </div>
                            <SingleSource src={`/img/${workItem.title.toLocaleLowerCase()}/iteration_12.gif`} />
                        </Col>
                    </Row>
                </Container>
            </section>
            <section>
                <Container fluid className='altbg5 altbg5Font my-5 py-5' id='impact'>
                        <Container className='work'>
                            <Row>
                                <Col lg='12' md='12' sm='12' className='text-start py-5' >
                                    <div className='workItalic'>
                                        The impact
                                    </div>
                                    <div className='workBold3 pb-4 mb-4'>
                                        Overall positive but nothing near perfect
                                    </div>
                                    <div className='pb-5 mb-5'>
                                        I tested the prototype with 3 prospective users before handoff, and they all reported positive feedback. The two funders regularly follow up with users after launch, the apps received both positive and negative feedback. Mostly positive experiences, but space for improvements. 1 year from its launch:
                                    </div>
                                    <div className='pb-4 mb-4 workBold text-center'>
                                        3500+ orders
                                        <br></br>
                                        1500+ downloads
                                        <br></br>
                                        62% completion rate
                                        <br></br>
                                        16 NPS (Net Promoter Score)
                                        <br></br>
                                        5.0 rating on Apple Store and Google Play
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                </Container>
            </section>
            <section>
                <Container className='work py-5 mt-5' id='conclusion'>
                    <Row>
                        <Col className='text-start py-5' >
                            <div className='workItalic'>
                                Summary
                            </div>
                            <div className='pb-4 mb-4'>
                                Working for Oyster was a great experience. I was involved beyond a UX designer, and everybody was engaging and open-minded. I truly enjoyed exploring what incentives would work for mobile app users and seeing how our service fulfilled their needs.
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='text-start py-5' >
                            <div className='workItalic'>
                                Takeaways
                            </div>
                            <div className='workBold3'>
                                A successful design should serve both users’ goals and business objectives
                            </div>
                            <div className='py-5'>
                                The founders initiated the project to help local coffee shops, which cannot be done without involving consumers. The agenda of the whole discovery phase was to figure out how to align UX strategies with business goal. 
                                <br></br>
                                <br></br>
                                <span className='workBold2'>I also learned to collaborate across disciplines early and often</span>. The project phase 1 was on a tight schedule, I benefited from the frequent communications with developers. Getting feedbacks early on helped me design a feasible solution that could be delivered on time.
                                <br></br>
                                <br></br>
                                <span>If I were to do this again, I would try harder to defend my users. For a brief period, we detected that users didn't use the app after downloading. After investigation, we found out there were issues with sign-up text verification. At that moment, I regretted not insisting on OAuth (sign in with Google/ Facebook ext.) when I first designed the app. I should have found a balance instead of cutting the function entirely.</span>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section>
                <Container fluid className='work py-5 mt-5'>
                    <img alt='' width="100%" src={`/img/${workItem.title.toLocaleLowerCase()}/bottom.jpg`} />
                </Container>
            </section>
            <Container className='work workFooter'>
                <WorkFooter workPrevious={workItem.workPrevious} workNext={workItem.workNext} />
            </Container>
            <Context workPrevious={workItem.workPrevious} workNext={workItem.workNext} contextItems={workItem.contextItems} />
        </>
    );
  }