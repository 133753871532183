import Image from "react-image-enlarger";
import React from 'react';
import {useLocation} from 'react-router-dom';
import {works} from './workconfig';

function SingleSource({ src }) {
    const [zoomed, setZoomed] = React.useState(false);
  
    return (
        <Image
          style={{ width: "100%"}}
          zoomed={zoomed}
          src={src}
          onClick={() => setZoomed(true)}
          onRequestClose={() => setZoomed(false)}
        />
    );
}

function WorkItems() {
    const location = useLocation();
    const pathname = location.pathname.replace("/","");
    const currentWorkItemIndex = works.findIndex(workitem=>workitem.Title.split("|")[0].toLowerCase()===decodeURI(pathname).toLowerCase());
    let workPrevious,workNext,contextItems,title;
    title = works[currentWorkItemIndex].Title.split("|")[0];
    contextItems = works[currentWorkItemIndex].ContextItems;

    if(currentWorkItemIndex===0){
        workPrevious=works[works.length-1].Title;
    }
    else{
        workPrevious=works[currentWorkItemIndex-1].Title;
    }

    if(currentWorkItemIndex===works.length-1){
        workNext=works[0].Title;
    }
    else{
        workNext=works[currentWorkItemIndex+1].Title;
    }

    return {workPrevious:workPrevious,workNext:workNext,contextItems:contextItems,title:title}
}

export {SingleSource,WorkItems}