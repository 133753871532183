import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import React from 'react';
import {SingleSource,WorkItems} from '../utility';
import WorkFooter from '../components/workfooter';
import Context from '../components/context';

export default function WorkPage() {
    const workItem = WorkItems();

    return (
        <>
            <Container className='pt-5 mt-5 work'>
                <div className='subTitle text-start'>Responsive Web App Design</div>
                <h1 className='mainTitle text-start py-2'>{workItem.title}</h1>
                <div className='mainDes text-start pb-4'>
                    United State Department of Agriculture (USDA) contributes to the overall health and stability of the agricultural industry while preserving the integrity of natural resources. 
                    &nbsp;<span className='workBold'>The challenge is to provide best available science so sustainable agriculture could be achieved.</span>
                </div>
                <Row className='py-5 text-start'>
                    <Col lg sm='12' className='py-3'>
                        <div className='workBold2'>
                            Client
                        </div>
                        <div>
                            USDA 
                        </div>
                    </Col>
                    <Col lg sm='12' className='py-3'>
                        <div className='workBold2'>
                            Role
                        </div>
                        <div>
                            UX Designer
                        </div>
                    </Col>
                    <Col lg sm='12' className='py-3'>
                        <div className='workBold2'>
                            Time
                        </div>
                        <div>
                            10.2022 - 03.2023
                        </div>
                    </Col>
                    <Col lg sm='12' className='py-3'>
                        <div className='workBold2'>
                            Team
                        </div>
                        <div>
                            An agile team of 12
                        </div>
                    </Col>
                </Row>
            </Container>
            <section id="section-2">
                <Container fluid className='pb-4'>
                    <img alt=''width="100%" src={`/img/${workItem.title.toLocaleLowerCase()}/cover.jpg`}/>
                    <Container className='work pt-4 nda text-start'>
                        *To comply with my non-disclosure agreement, I have omitted and obfuscated confidential information in this case study.
                    </Container>
                </Container>
            </section>
            <section>
                <Container className='py-5 my-5 work'>
                    <div className='text-start pb-5'>
                        Check FOTG at <a href='https://efotg.sc.egov.usda.gov/#/'>https://efotg.sc.egov.usda.gov/#/</a>.
                    </div>
                    <div className='mainbg'>
                        <Row>
                            <Col lg sm='12' className='text-start py-5'>
                                <div className='workBold3 pb-5 w-75'>
                                    What is Field Office Technical Guides (FOTG) ?
                                </div>
                                <div className='pb-3 mb-5'>
                                Natural Resources Conservation Service (NRCS) is USDA's primary land conservation agency that provides science-based data and advice. Field Office Technical Guides (FOTG) are the main scientific references for NRCS. The guide contains technical information about soil, water, air, plant and animal resource conservation. 
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className='mainbg'>
                        <Row>
                            <Col lg sm='12' className='text-start py-2'>
                                <div className='workBoldItalic' id="context">
                                    Context & Challenge
                                </div>
                                <div className='workBold3 pb-5 w-75'>
                                    High abandon rate on mobile devices
                                </div>
                                <div className='pb-3 mb-5'>
                                    The idea of making FOTG responsive didn't just appear out of nowhere, there was a specific source for it: Google Analytics reports. No clear message could be interpreted from the high mobile engagement rate alone, yet combining the fact that mobile engagement time is significantly lower, we could imagine that plenty of users tried to access FOTG on mobile devices but abandoned the journey. And when examining FOTG on small screens, we found it was almost impossible to navigate because of the disproportional real estate, cluttered layout and small text.
                                </div>
                                <div className='my-4'>
                                    <SingleSource src={`/img/${workItem.title.toLocaleLowerCase()}/ga_report.jpg`} />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
            <section>
                <Container fluid>
                    <img alt=''width="100%" src={`/img/${workItem.title.toLocaleLowerCase()}/breaker.jpg`}/>
                </Container>
            </section>
            <section>
                <Container className='py-5 my-5 work' id='discovery_research'>
                    <Row>
                        <Col lg xs='12' className='text-start py-2'>
                            <div className='workBoldItalic pt-5' >
                                Discovery research
                            </div>
                            <div className='workBold3'>
                                In-depth User Interview
                            </div>
                            <div className='pt-5 mt-3'>
                                Defined by manual, there are 3 user roles: Content Viewers, Content Managers, and Technical Support. FOTG provides read-only access on publicly available information to Content Viewers. Administrative access (Content Managers and Technical Support) in FOTG is granted through authentication. Considering content managers and technical support cannot work efficiently on small screens, it was decided that no log-in functionality will be provided for mobile devices. Then the question became that for content viewers, what features should be carried over to smaller screens? To answer this, The other designer M and I conducted discovery research,&nbsp;
                                <a href={`/img/${workItem.title.toLocaleLowerCase()}/Discovery Research Plan.pdf`} target="_blank">Discovery Research Plan</a>.
                                We were provided with 4 persona groups: planner, partner, institution, and the general public.
                            </div>
                            <div className='pt-5 mt-3'>
                                In total, we interview 8 users, and by talking with them, we hoped to
                                <div>
                                    •	Understand content viewers' use cases.
                                    <br></br>
                                    •	Decide what to include and exclude in the responsive design.
                                    <br></br>
                                    •	Identify users' expectations regarding using FOTG on a mobile device.
                                </div>
                            </div>
                            <div className='pt-5 mt-3 workBold2'>
                                Discovery interview recordings
                                <SingleSource src={`/img/${workItem.title.toLocaleLowerCase()}/interview_recordings.jpg`} />
                            </div>
                            <div className='py-5 my-3'>
                                We analyzed and summarized notes and reflected constructive feedback in&nbsp;
                                <a href={`/img/${workItem.title.toLocaleLowerCase()}/Discovery Research Report.pdf`} target="_blank">Discovery Research Report</a>.
                                Next, I created the personas, attached pain points and user needs to the existing flows and drew conclusions on functionalities that should be provided in the responsive FOTG.
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg xs='12'>
                            <SingleSource alt='' src={`/img/${workItem.title.toLocaleLowerCase()}/survey_2_1.jpg`} />
                        </Col>
                        <Col lg xs='12'>
                            <SingleSource alt='' src={`/img/${workItem.title.toLocaleLowerCase()}/survey_2_2.jpg`} />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg xs='12' className='pt-4 pr-4'>
                            <SingleSource alt='' src={`/img/${workItem.title.toLocaleLowerCase()}/survey_2_3.jpg`} />
                        </Col>
                        <Col lg xs='12' className='pt-4 pr-4'>
                            <SingleSource alt='' src={`/img/${workItem.title.toLocaleLowerCase()}/survey_2_4.jpg`} />
                        </Col>
                    </Row>
                    <Row className='py-3 mt-3'>
                        <Col lg xs='12' className='text-start py-2'>
                            <div className='pt-5 mt-3 workBold2' id='user_flow'>
                                User flow: use FOTG as a resource
                                <SingleSource src={`/img/${workItem.title.toLocaleLowerCase()}/user_flow.jpg`} />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section>
                <Container fluid className='py-5 my-5 work'>
                    <div className='breaker altbg6'>

                    </div>
                </Container>
            </section>
            <section>
                <Container className='work'>
                    <Row className='py-5 mt-5'>
                        <Col lg xs='12'className='text-start' id="lo-fi_prototype">
                            <div className='workItalic'>
                                Lo-fi prototype of existing user flow
                            </div>
                            <div className='pt-5 mt-3'>
                                For wireframes, M and I collaborated on Figma and came up with several  ideas. We then rendered a couple into the lo-fi prototype, sat down with the TPM, BA, and Devs, collectively selected one, and moved into hi-fi.
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg xs='12' className='pt-4 pr-4'>
                            <div className='workBold2 text-start'>
                                Wireframe examples
                            </div>
                            <SingleSource alt='' src={`/img/${workItem.title.toLocaleLowerCase()}/lofi_1.jpg`} />
                        </Col>
                        <Col lg xs='12' className='pt-4 pr-4'>
                            <div className='workBold2 text-start'>
                                Lo-fi candidates
                            </div>
                            <SingleSource alt='' src={`/img/${workItem.title.toLocaleLowerCase()}/lofi_2.jpg`} />
                        </Col>
                    </Row>
                    <Row className='pb-5 mb-5'>
                        <Col lg xs='12' className='pt-4 pr-4'>
                            <div className='pt-5 mt-3 workBold2 text-start'>
                                Lo-fi archive
                            </div>
                            <SingleSource alt='' src={`/img/${workItem.title.toLocaleLowerCase()}/lofi_3.jpg`} />
                        </Col>
                        <Col lg xs='12' className='pt-4 pr-4'>
                            <div className='pt-5 mt-3 workBold2 text-start'>
                                Lo-fi final
                            </div>
                            <SingleSource alt='' src={`/img/${workItem.title.toLocaleLowerCase()}/lofi_4.jpg`} />
                        </Col>
                    </Row>
                </Container>
            </section>
            <section>
                <Container fluid className='py-5 my-5 work'>
                    <div className='breaker altbg6'>

                    </div>
                </Container>
            </section>
            <section>
                <Container className='work'>
                    <Row className='py-5 mt-5'>
                        <Col lg xs='12'className='text-start' id="hi-fi_prototype">
                            <div className='workItalic'>
                                Hi-fi prototype of new user flow
                            </div>
                            <div className='workBold3'>
                                We sold the idea to the Product Owner!
                            </div>
                            <div className='pt-5 mt-3'>
                                To find a document, users can use either document tree and document search. The document tree contains layers of folders, and lots of the them include hundreds of documents. No doubt, locating documents in those folders can be challenging on mobile screens.
                            </div>
                            <div className='pt-5 mt-3'>                                
                                To provide a work around, we introduced in-folder search functionality. We brought the new flow to Government Product Owner (GPO) and Subject Matter Expert (SME), and were granted more time for the design and implementation work as they considered our proposal of great business value. Following, we went through iterations and reached a version for the usability test. 
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg xs='12' className='pt-4 pr-4'>
                            <div className='workBold2 text-start'>
                                New flow prototype - V1
                            </div>
                            <SingleSource alt='' src={`/img/${workItem.title.toLocaleLowerCase()}/hifi_1.jpg`} />
                        </Col>
                        <Col lg xs='12' className='pt-4 pr-4'>
                            <div className='workBold2 text-start'>
                                New flow prototype - V2
                            </div>
                            <SingleSource alt='' src={`/img/${workItem.title.toLocaleLowerCase()}/hifi_2.jpg`} />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg xs='12' className='pt-4 pr-4'>
                            <div className='pt-5 mt-3 workBold2 text-start'>
                                New flow prototype - V3
                            </div>
                            <SingleSource alt='' src={`/img/${workItem.title.toLocaleLowerCase()}/hifi_3.jpg`} />
                        </Col>
                        <Col lg xs='12' className='pt-4 pr-4'>
                            <div className='pt-5 mt-3 workBold2 text-start'>
                                New flow prototype - V4
                            </div>
                            <SingleSource alt='' src={`/img/${workItem.title.toLocaleLowerCase()}/hifi_4.jpg`} />
                        </Col>
                    </Row>
                    <Row className='pb-5 mb-5'>
                        <Col lg xs='12' className='pt-4 pr-4'>
                            <div className='pt-5 mt-3 workBold2 text-start'>
                                New flow prototype final
                            </div>
                            <SingleSource alt='' src={`/img/${workItem.title.toLocaleLowerCase()}/hifi_final.jpg`} />
                        </Col>
                    </Row>
                </Container>
            </section>
            <section>
                <Container fluid className='py-5 my-5 work'>
                    <div className='breaker altbg6'>

                    </div>
                </Container>
            </section>
            <section>
                <Container className='work'>
                    <Row className='py-5 mt-5'>
                        <Col lg xs='12'className='text-start' id="usability_test">
                            <div className='workItalic'>
                                Usability test & expert review
                            </div>
                            <div className='workBold3'>
                                Users always surprise us
                            </div>
                            <div className='pt-5 mt-3'>
                                We invited 4 participants for the usability test,&nbsp;
                                <a href={`/img/${workItem.title.toLocaleLowerCase()}/Usability Test Plan.pdf`} target="_blank">Usability Test Plan</a>, 
                                we asked them to perform usability tasks on the prototype and answer debriefing questions so we could
                            </div>
                            <div>
                                •	Determine if the flows were intuitive/ efficient/ easy to learn.
                                <br></br>
                                •	Evaluate if the notations/ CAT/ signifiers were understandable/ sufficient. 
                            </div>
                            <div className='pt-5 mt-3'>                                
                                Based on the findings,&nbsp;
                                <a href={`/img/${workItem.title.toLocaleLowerCase()}/Usability Test Research Report.pdf`} target="_blank">Usability Test Reports</a>,
                                we iterated again before conducting expert reviews with GPO and SME. GPO and SME soon nodded at the design and we proceeded the design hand-off process.
                            </div>
                        </Col>
                    </Row>
                    <div className='pt-3 mt-3 text-start workBold2'>
                        Usability test recordings
                        <SingleSource src={`/img/${workItem.title.toLocaleLowerCase()}/usability_test.jpg`} />
                    </div>
                </Container>
            </section>
            <section>
                <Container fluid className='py-5 my-5 work'>
                    <div className='breaker altbg6'>

                    </div>
                </Container>
            </section>
            <section>
                <Container className='work'>
                    <Row className='py-5 mt-5'>
                        <Col lg xs='12'className='text-start' id="iterations">
                            <div className='workItalic'>
                                Iterations
                            </div>
                            <div className='workBold3'>
                                All-round improvements
                            </div>
                            <div className='pt-5 mt-3 workBold'>
                                Iterations on user flows
                            </div>
                            <div>                                
                                Two functionalities were added: "pre-search filter & sort", and "search within folders". The new flow makes finding documents more efficient and allows locating documents in specific branches.
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg xs='12' className='pt-4 pr-4'>
                            <Row>
                                <Col lg='12' xs='12'>
                                    <div className='workBold2 text-start'>
                                        User flow, V1
                                    </div>
                                    <SingleSource alt='' src={`/img/${workItem.title.toLocaleLowerCase()}/user_flow_v1.jpg`} />
                                </Col>
                                <Col lg='12' xs='12' className='pt-4 pr-4'>
                                    <div className='workBold2 text-start'>
                                        User flow, V2: "Search within folder"
                                    </div>
                                    <SingleSource alt='' src={`/img/${workItem.title.toLocaleLowerCase()}/user_flow_v2.jpg`} />
                                </Col>
                            </Row>
                        </Col>
                        <Col lg xs='12' className='pt-4 pr-4'>
                            <div className='workBold2 text-start'>
                                User flow, Final: "Pre-search filter & sort"
                            </div>
                            <SingleSource alt='' src={`/img/${workItem.title.toLocaleLowerCase()}/user_flow_final.jpg`} />
                        </Col>
                    </Row>
                    <Row className='py-5 mt-5'>
                        <Col lg xs='12'className='text-start' id="personas">
                            <div className='pt-5 mt-5 workBold'>
                                Iterations on state page
                            </div>
                            <div>                                
                                From interviews, we learned that 3/4 participants often keep an eye on newly published practices. So we pre-populated the state arrival page with documents in reverse chronological order. Worried if loading all contents would diminish speed, I engaged developers and decided that upon the user's arrival, only show documents published in the past year.
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg xs='6' className='pt-4 pr-4'>
                            <div className='workBold2 text-start'>
                                State page, V1:
                            </div>
                            <div className='text-start'>
                                Buttons
                            </div>
                            <div className='text-start pb-2 mb-2'>
                                &nbsp;
                            </div>
                            <SingleSource alt='' src={`/img/${workItem.title.toLocaleLowerCase()}/statepage_1.jpg`} />
                        </Col>
                        <Col lg xs='6' className='pt-4 pr-4'>
                            <div className='workBold2 text-start'>
                                State page, V2:
                            </div>
                            <div className='text-start'>
                                Sticking search box
                            </div>
                            <div className='text-start pb-2 mb-2'>
                                &nbsp;
                            </div>
                            <SingleSource alt='' src={`/img/${workItem.title.toLocaleLowerCase()}/statepage_2.jpg`} />
                        </Col>
                        <Col lg xs='6' className='pt-4 pr-4'>
                            <div className='workBold2 text-start'>
                                State page, V3:
                            </div>
                            <div className='text-start pb-2 mb-2'>
                                Quick access to popular contents
                            </div>
                            <SingleSource alt='' src={`/img/${workItem.title.toLocaleLowerCase()}/statepage_3.jpg`} />
                        </Col>
                        <Col lg xs='6' className='pt-4 pr-4'>
                            <div className='workBold2 text-start'>
                                State page, Final:
                            </div>
                            <div className='text-start pb-2 mb-2'>
                                Pre-populate with newly published practices
                            </div>
                            <SingleSource alt='' src={`/img/${workItem.title.toLocaleLowerCase()}/statepage_final.jpg`} />
                        </Col>
                    </Row>
                    <Row className='py-5 mt-5'>
                        <Col lg xs='12'className='text-start' id="personas">
                            <div className='pt-5 mt-5 workBold'>
                                Iterations on search & filter functionality
                            </div>
                            <div>                                
                                By populating the state page with documents, the pre-search filter and sort functions became available. This change allows users to filter and sort all state documents. Shortcuts to popular content are provided at search page. 
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='pt-4 pr-4'>
                            <div className='text-start pb-2 mb-2'>
                                <span className='workBold2'>Search & Filter, V1:</span> Post-search filter
                            </div>
                            <SingleSource alt='' src={`/img/${workItem.title.toLocaleLowerCase()}/filter_1.jpg`} />
                        </Col>
                    </Row>
                    <Row>
                        <Col className='pt-4 pr-4'>
                            <div className='pt-5 mt-3 text-start pb-2 mb-2'>
                                <span className='workBold2'>Search & Filter, V2:</span> Search box & welcome text
                            </div>
                            <SingleSource alt='' src={`/img/${workItem.title.toLocaleLowerCase()}/filter_2.jpg`} />
                        </Col>
                    </Row>
                    <Row>
                        <Col className='pt-4 pr-4'>
                            <div className='pt-5 mt-3 text-start pb-2 mb-2'>
                                <span className='workBold2'>Search & Filter, Final:</span> Pre & post-search filter and shortcuts to popular contents
                            </div>
                            <SingleSource alt='' src={`/img/${workItem.title.toLocaleLowerCase()}/filter_3.jpg`} />
                        </Col>
                        <Col lg='3' className='pt-4 pr-4'>

                        </Col>
                    </Row>
                    <Row className='py-5 mt-5'>
                        <Col lg xs='12'className='text-start' id="personas">
                            <div className='pt-5 mt-5 workBold'>
                                Iterations on tree
                            </div>
                            <div>                                
                                FOTG folders often contain long lists of documents. It’s common occurrence for users to scroll down a folder before they realize they entered a wrong branch. And when this happens, users have to scroll up all the way to top to return to previous layers. To prevent this unpleasant long scrolling and to nurture user control, we added buttons that enable users to backtrack on the choice made with ease.
                            </div>
                        </Col>
                    </Row>
                    <Row>
                    <Col className='pt-4 pr-4'>
                            <div className='text-start pb-2 mb-2'>
                                <span className='workBold2'>Tree, V1: </span> Accordions
                            </div>
                            <SingleSource alt='' src={`/img/${workItem.title.toLocaleLowerCase()}/tree_1.jpg`} />
                        </Col>
                    </Row>
                    <Row>
                        <Col className='pt-4 pr-4'>
                            <div className='pt-5 mt-3 text-start pb-2 mb-2'>
                                <span className='workBold2'>Tree, V2: </span> Menu slide-out with folder name provided to reduce cognitive load
                            </div>
                            <SingleSource alt='' src={`/img/${workItem.title.toLocaleLowerCase()}/tree_2.jpg`} />
                        </Col>
                        <Col lg='3' className='pt-4 pr-4'>

                        </Col>
                    </Row>
                    <Row>
                        <Col className='pt-4 pr-4 pb-3 mb-3'>
                            <div className='pt-5 mt-3 text-start pb-2 mb-2'>
                                <span className='workBold2'>Tree, Final: </span> Back to all document functionality is added to enhance user control
                            </div>
                            <SingleSource alt='' src={`/img/${workItem.title.toLocaleLowerCase()}/tree_3.jpg`} />
                        </Col>
                        <Col lg='3' className='pt-4 pr-4'>

                        </Col>
                    </Row>
                </Container>
            </section>
            <section>
                <Container fluid className='py-5 my-5 work'>
                    <div className='breaker altbg6'>

                    </div>
                </Container>
            </section>
            <section>
                <Container className='work' id='final_design'>
                    <Row className='py-5 mt-5'>
                        <Col lg xs='12'>
                            <div className='workBold pb-3 mb-3'>
                                Final design video
                            </div>
                            <video width="50%" controls>
                                <source src={`/img/${workItem.title.toLocaleLowerCase()}/demo.mp4`} type="video/mp4" />
                            </video>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section>
                <Container fluid className='py-5 my-5 work'>
                    <div className='breaker altbg6'>

                    </div>
                </Container>
            </section>
            <section>
                <Container className='work'>
                    <Row className='py-5 mt-5'>
                        <Col lg xs='12'className='text-start' id="impact">
                            <div className='workItalic'>
                                The impact
                            </div>
                            <div className='workBold'>
                                Happy clients and appreciative users
                            </div>
                            <div className='pt-5 mt-3'>
                                When GPO and SME were presented with the final design, they were both very satisfied and expressed gratitude to our team. They praised our works and recognized the Responsive FOTG would not look visually appealing but also supports project's mission and objectives. 
                            </div>
                            <div className='pt-5 mt-3'>
                                End users who tried out prototype acted genuinely thrilled for its impact on their work efficiency. Being able to access practice on their phone while in the field would drastically boost their work efficiency and improve customer service been provided to their clients.
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg xs='12' className='pt-4 pr-4'>
                            <div className='workBold2 text-start'>
                                All end users are willing to use and recommend Responsive FOTG
                            </div>
                            <SingleSource alt='' src={`/img/${workItem.title.toLocaleLowerCase()}/impact_1.jpg`} />
                        </Col>
                    </Row>
                    <Row className='pb-5 mb-5'>
                        <Col lg xs='12' className='pt-4 pr-4 pb-5'>
                            <div className='pt-5 mt-3 workBold2 text-start'>
                                What users like about Responsive FOTG
                            </div>
                            <SingleSource alt='' src={`/img/${workItem.title.toLocaleLowerCase()}/impact_2.jpg`} />
                        </Col>
                    </Row>
                </Container>
            </section>
            <section>
                <Container fluid className='altbg6 altbg6Font py-5' id='conclusion'>
                    <div>
                        <Container className='work py-5'>
                            <Row>
                                <Col lg='12' md='12' sm='12' className='text-start py-5' >
                                    <div className='workItalic pb-2 mb-2'>
                                        Learning
                                    </div>
                                    <div className='workBold3 pb-4 mb-4'>
                                        Good product must serve all users
                                    </div>
                                    <div>
                                        For a tool like FOTG, domain knowledge makes user experiences hugely different. Advanced users are able to navigate the Document Tree smoothly. However, casual users like general public and new hires found it almost impossible to navigate the document tree because that they don’t understand the hierarchical structure. In order to accommodate all audience, we introduced a more robust search functionality to provide alternative for finding documents.
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg='12' md='12' sm='12' className='text-start py-5' >
                                    <div className='workItalic pb-2 mb-2'>
                                        Next step
                                    </div>
                                    <div className='workBold3 pb-4 mb-4'>
                                        Consistency among screens
                                    </div>
                                    <div>
                                        Flow change is a rather large evolution. Down the road, we will modernize the desktop version and align behaviors among different sized screens.
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg='12' md='12' sm='12' className='text-start py-5' >
                                    <div className='workBold3 pb-4 mb-4'>
                                        Help and documentation 
                                    </div>
                                    <div>                                      
                                        Responsive FOTG quick guide is being written and will be accessed from the “User Guide” button from menu. 
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </Container>
            </section>
            <Container className='work workFooter'>
                <WorkFooter workPrevious={workItem.workPrevious} workNext={workItem.workNext} />
            </Container>
            <Context workPrevious={workItem.workPrevious} workNext={workItem.workNext} contextItems={workItem.contextItems} />
        </>
    );
  }