import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import React from 'react';
import {SingleSource,WorkItems} from '../utility';
import WorkFooter from '../components/workfooter';
import Context from '../components/context';

export default function WorkPage() {
    const workItem = WorkItems();

    return (
        <div className='work-fontColor2'>
            <Container className='pt-5 mt-5 work'>
                <div className='subTitle text-start'>Responsive Website Redesign</div>
                <h1 className='mainTitle text-start py-2'>{workItem.title}</h1>
                <div className='mainDes text-start pb-4'>
                Small Business Development Centers (SBDC) is the largest service organization under U.S. Small Business Administration that provides business counseling and training to small business owners.&nbsp;
                    <span className='workBold'>The challenge is to discover pain points and propose solutions to improve effectiveness</span>.
                </div>
                <Row className='py-5 text-start'>
                    <Col lg sm='12' className='py-3'>
                        <div className='workBold2'>
                            Role
                        </div>
                        <div>
                            UX Desinger
                        </div>
                    </Col>
                    <Col lg sm='12' className='py-3'>
                        <div className='workBold2'>
                            Time
                        </div>
                        <div>
                            2020
                        </div>
                    </Col>
                    <Col lg='5' sm='12' className='py-3'>
                        <div className='workBold2'>
                            Team
                        </div>
                        <div>
                            1 Developer, 1 UX Designer
                        </div>
                    </Col>
                </Row>
            </Container>
            <section>
                <Container fluid className='pb-4'>
                    <img alt=''width="100%" src={`/img/${workItem.title.toLocaleLowerCase()}/cover.jpg`}/>
                </Container>
            </section>
            <section>
                <Container className='work'>
                    <div className='mainbg'>
                        <Row>
                            <Col lg sm='12' className='text-start py-5 my-5'>
                                <div className='workBoldItalic pt-5' id="context">
                                    Context & Challenge
                                </div>
                                <div className='workBold3 pb-5 mb-3 w-75'>
                                    High abandon rate calls for a redesign
                                </div>
                                <div className='workBold2'>
                                    Goals & Metrics
                                </div>
                                <div>
                                    •	Create a pleasant onboarding experience.
                                    <br></br>
                                    •	Streamline the appointment-making process.
                                    <br></br>
                                    •	Provide easy access to popular information.
                                </div>
                            </Col>
                            <Col lg sm='12' className='text-start py-5 my-5'>
                                <div className='text-start pt-5'>
                                    &nbsp;
                                </div>
                                <div className='pb-3 mb-5'>
                                    This whole project started with a conversation my colleague had with me. My colleague who works at System Administration Office told me the abandon rate on the “Request for Consulting” page was disturbingly high. We instantly knew something must have impeded users. Feeling a sense of empathy, we decided to explore improvement opportunities and present them to the executives. The redesign has been discussed with the regional directors but has never been implemented.
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
            <section>
                <Container fluid>
                    <img alt=''width="100%" src={`/img/${workItem.title.toLocaleLowerCase()}/breaker.png`}/>
                </Container>
            </section>
            <section>
                <Container className='work'>
                    <Row>
                        <Col lg sm='12' className='text-start py-5 my-5'>
                            <div className='workBoldItalic pt-5' id="usability_study">
                                Usability study
                            </div>
                            <div className='workBold3 pb-5 mb-5 w-75'>
                                Decide redesign priorities with usability study
                            </div>
                            <div className='w-75'>
                                To diagnose, I drafted 3 prompts, which reflect the most typical reasons that lead users to visit nysbdc.org.
                                <br></br>
                                <br></br>
                                1.	To make an appointment.
                                <br></br>
                                2.	To register for a training session.
                                <br></br>
                                3.	To find a specific piece of information.
                                <br></br>
                                <br></br>
                                I interviewed 8 participants, synthesized my findings, and then created a matrix. 
                            </div>
                        </Col>
                        <Col lg sm='12' className='text-middle py-5 my-5' id="empathy_map">
                            <div className='pt-5'>
                                &nbsp;
                            </div>
                            <SingleSource alt='' src={`/img/${workItem.title.toLocaleLowerCase()}/finding.png`} />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg='12' md='12' sm='12' className='text-start pb-5'>
                            <SingleSource alt='' src={`/img/${workItem.title.toLocaleLowerCase()}/affinity.png`} />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg='12' md='12' sm='12' className='text-start pb-5'>
                            <div>
                                We decided to start with the features of Making Appointment, Newsletter subscription, and Homepage, as the three categories bring high impact with less engineering effort. 
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg='12' md='12' sm='12' className='text-start pb-5'>
                            <SingleSource alt='' src={`/img/${workItem.title.toLocaleLowerCase()}/matrix.png`} />
                        </Col>
                    </Row>
                </Container>
            </section>
            <section>
                <Container fluid>
                    <Row className='mx-0 py-5 my-5'>
                        <Col className='px-0'>
                            <div className='banner leftbanner'>

                            </div>
                        </Col>
                        <Col lg='10' md='10' sm='10' xs='10' className='px-0'>
                            <Container className='work'>
                                <Row>
                                    <Col lg='12' md='12' sm='12' xs='12' className='text-start py-5' id="personas">
                                        <div className='workBold py-2 my-2'>
                                            Two types of users: <span className='workBold3'>Experienced & New Business Owner</span>
                                        </div>
                                    </Col>
                                    <Col lg='12' md='12' sm='12' xs='12' className='text-start pb-5'>
                                        The common patterns of my clients helped me create two personas: Persona Jason represents an experienced entrepreneur who needs specific consulting, and Janet is new to the business world who appreciates all kinds of helps.
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg='10' md='10' sm='10' xs='12' className='text-start pb-5 roundImg'>
                                        <SingleSource src={`/img/${workItem.title.toLocaleLowerCase()}/personaanna.png`} />
                                    </Col>
                                    <Col lg='2' md='2' sm='2' xs='0'></Col>
                                </Row>
                                <Row>
                                    <Col lg='2' md='2' sm='2' xs='0'></Col>
                                    <Col lg='10' md='10' sm='10' xs='12' className='text-start pb-5 align-self-end roundImg'>
                                        <SingleSource className='float-end' src={`/img/${workItem.title.toLocaleLowerCase()}/personaandrew.png`} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg='2' md='2' sm='2' xs='0'></Col>
                                    <Col lg='10' md='10' sm='10' xs='12' className='text-start pb-5 align-self-end'>
                                    <div id='define'>
                                        <div className='workBoldItalic'>Define</div>
                                        Going through the journey maps, I got&nbsp;<span className='workBold2'>insights:</span>
                                        <br></br>
                                        •	The need for a simple and quick appointment making process.
                                        <br></br>
                                        •	The freedom to select location, advisor, and time.
                                        <br></br>
                                        •	The appointment reminder and managing function.
                                        <br></br>
                                        •	Easy newsletter subscription.
                                        <br></br>
                                        •	A helpful homepage.
                                    </div>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                        <Col className='align-self-end px-0'>
                            <div className='banner rightbanner float-end'>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section>
                <Container className='work py-5 my-5'>
                    <Row>
                        <Col lg='12' md='12' sm='12' className='text-start py-5 mt-5' id="improve_1">
                            <div className='workBoldItalic pb-2 mb-2'>
                                Improvement 1
                            </div>
                            <div className='workBold3 pb-2 mb-2'>
                                Streamline the appointment-making process
                            </div>
                            <div className='pt-3 mt-3'>
                                The appointment process is unpleasantly complicated, with lots of back-and-forth before the date & time is set. The current appointment scheduling process is inefficient and time-consuming. It takes at least 3 days to set an appointment and sometimes even longer if the chosen time turned out unavailable.
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Container fluid className='work' id='pain_points'>
                    <Row className='mx-0'>
                        <Col className='leftBannerWideContainerFill'>
                        
                        </Col>
                        <Col lg='10' className='leftBannerWide'>
                            <Container>
                                <Row>
                                    <Col className='text-start py-3'>
                                        <div>
                                            The major <span className='workBold3'>pain points</span> are:
                                            <br></br>
                                            <br></br>
                                            1.	High abandon rate due to the long intaking form.
                                            <br></br>
                                            2.	Long waiting time.
                                            <br></br>
                                            3.	Back-n-forth due to the unideal locations and unavailable time slots.
                                            <br></br>
                                            4.	Failure of matching with suitable advisors. 
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                        <Col>
                        </Col>
                    </Row>
                    <Row className='mx-0'>
                        <Col></Col>
                        <Col lg='10' className='workItalic text-start pt-5 pb-2'>
                            Appointment making user Flow
                        </Col>
                        <Col></Col>
                    </Row>
                    <Row className='mx-0'>
                        <Col></Col>
                        <Col lg='10' id='user_flow'>
                            <SingleSource className='float-end' src={`/img/${workItem.title.toLocaleLowerCase()}/userflow1.png`} />
                        </Col>
                        <Col></Col>
                    </Row>
                    <Row className='mx-0'>
                        <Col></Col>
                        <Col lg='10' className='pt-3'>
                            <SingleSource className='float-end' src={`/img/${workItem.title.toLocaleLowerCase()}/userflow2.png`} />
                        </Col>
                        <Col></Col>
                    </Row>
                    <Row className='mx-0'>
                        <Col></Col>
                        <Col lg='10' className='pt-3'>
                            <SingleSource className='float-end' src={`/img/${workItem.title.toLocaleLowerCase()}/userflow3.png`} />
                        </Col>
                        <Col></Col>
                    </Row>
                    <Container>
                        <Row>
                            <Col className='text-start py-5'>
                                <div>
                                    <span className='workBold'>Developer Challenged</span>
                                    <br></br>
                                    Jessica brought it to my attention that solution V.1 may lead to double booking as advisors’ availability will only be synced hourly. I then came up with two alternate options with varying levels of complexity. As a team, we settled on the option of letting advisors manually confirm appointments with the system. 
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </section>
            <section>
                <Container fluid className='work' id='solutions'>
                    <Row className='mx-0'>
                        <Col>
                        
                        </Col>
                        <Col lg='10' className='rightBannerWide'>
                            <Container>
                                <Row>
                                    <Col className='text-start py-3'>
                                        <div>
                                            The <span className='workBold3'>solutions</span> are set as:
                                            <br></br>
                                            <br></br>
                                            •	The solution to pain points 1,3 & 4: Instead of collecting information and making decisions for clients, provide information and let clients decide. 
                                            <br></br>
                                            •	The solution to pain points 2 & 3: Let client select location, advisor, and time and instantly book the appointment through websites. Show all options upfront, so clients don’t waste time guessing.
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                        <Col className='rightBannerWideContainerFill'>
                        </Col>
                    </Row>
                    <Container>
                        <Row>
                            <Col lg='5' xs='5' className='text-start py-5'>
                                <SingleSource src={`/img/${workItem.title.toLocaleLowerCase()}/homepage_left.png`} />
                            </Col>
                            <Col lg='2' xs='2' className='text-center py-5'>
                                <img alt='' width="75%" src={`/img/formsignup.png`} />
                            </Col>
                            <Col lg='5' xs='5' className='text-start py-5'>
                                <Row>
                                    <Col lg='12'>
                                        <SingleSource src={`/img/${workItem.title.toLocaleLowerCase()}/homepage_right.png`} />
                                    </Col>
                                </Row>
                                <Row className='removeFormText_desktop'>
                                    <Col lg='12' xs='0' sm='12' md='12'>
                                        <div className='workBold mt-5 pt-5'>Remove the long form</div>
                                        the current appointment request form is annoyingly long, asking for information that some clients don't wish to disclose. The purpose of requesting the information is so that the client can be matched with an advisor with the right expertise. However, matches sometimes fail, due to insufficient understanding of clients’ needs and contextual backgrounds. 
                                        <br></br>
                                        <br></br>
                                        The new design provides advisor' bio so client can make informed choices. Empowering the client to make decisions guarantees the desired location, the best-matching advisor, and time at their best convenience.
                                    </Col>
                                </Row>                            
                            </Col>
                        </Row>
                        <Row className='removeFormText_phone text-start'>
                            <Col>
                                <div className='workBold'>Remove the long form</div>
                                the current appointment request form is annoyingly long, asking for information that some clients don't wish to disclose. The purpose of requesting the information is so that the client can be matched with an advisor with the right expertise. However, matches sometimes fail, due to insufficient understanding of clients’ needs and contextual backgrounds. 
                                <br></br>
                                <br></br>
                                The new design provides advisor' bio so client can make informed choices. Empowering the client to make decisions guarantees the desired location, the best-matching advisor, and time at their best convenience.
                            </Col>
                        </Row>    
                        <Row>
                            <Col className='text-start py-5 mb-5'>
                                <SingleSource className='float-end' src={`/img/${workItem.title.toLocaleLowerCase()}/locationadvisortime.png`} />
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </section>
            <section>
                <Container fluid className='work altbg3 py-5 mt-5' id='iteration'>
                    <Container>
                        <Row className='py-5'>
                            <Col className='text-center altbg3Font mb-5'>
                            <span className='workItalic'>Iteration</span>
                            <br></br>
                            As expected, the process was more of an organic journey than a linear path, consisting of a revisit of the previous steps and reiteration. Several changes were made according to the findings from the usability study. Two prominent changes were:
                            </Col>
                        </Row>
                    </Container>
                    <Row className='mx-0'>
                        <Col></Col>
                        <Col lg='10' className='text-center py-5 mainbg roundCol'>
                            <div className='py-5'>
                                Iteration Change 1.	The space usage evolution of “select location” page.
                            </div>
                            <div className='w-75 mx-auto'>
                                <SingleSource className='float-end' src={`/img/${workItem.title.toLocaleLowerCase()}/location123.png`} />
                            </div>
                            <div className='py-5 w-75 mx-auto'>
                                Iteration Change 2.	Show selections that have been made at the step bars. Interfaces that promote recognition reduce the amount of cognitive effort.
                            </div>
                            <div className='w-50 mx-auto pb-5'>
                                <SingleSource className='float-end' src={`/img/${workItem.title.toLocaleLowerCase()}/showselections.png`} />
                            </div>
                        </Col>
                        <Col></Col>
                    </Row>
                    <Container id='prototype'>
                        <Row className='mt-5'>
                            <Col lg='12' className='text-center pt-5 mt-5 altbg3Font'>
                                <span className='workItalic'>Animated Prototype</span>
                                <div className='pt-3'>
                                    <video width="100%" controls>
                                        <source src={`/img/${workItem.title.toLocaleLowerCase()}/sbdcvideo.mp4`} type="video/mp4" />
                                    </video>
                                </div>
                            </Col>
                            <Col lg='12' className='text-center pb-1 mb-1 altbg3Font'>
                                <a target='_blank' rel="noreferrer"  href='https://xd.adobe.com/view/349da8ca-658b-492f-b238-4387f0b557ef-61ce/?fullscreen'>Try the clickable prototype here</a>
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </section>
            <section>
                <Container className='work' id='improve_2'>
                    <Row className='py-5 mt-5'>
                        <Col lg='12' md='12' sm='12' className='text-start pt-5 mt-5' id="improve_1">
                            <div className='workBoldItalic pb-2 mb-2'>
                                Improvement 2
                            </div>
                            <div className='workBold3 pb-2 mb-2'>
                                Facelifting the homepage
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Container fluid className='work' id='pain_points_2'>
                    <Row className='mx-0'>
                        <Col className='leftBannerWideContainerFill mb-3'>
                        
                        </Col>
                        <Col lg='10' className='leftBannerWide mb-3'>
                            <Container>
                                <Row>
                                    <Col className='text-start py-3' lg='6'>
                                        <div>
                                            Through heuristic testing, I discovered design <span className='workBold3'>problems</span> of:
                                            <br></br>
                                            •	Inefficient use of the space. 
                                            <br></br>
                                            •	Text heavy, unfriendly to visually impaired users. 
                                            <br></br>
                                            •	Popular information is hard to find.
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                        <Col>
                        </Col>
                    </Row>
                </Container>
                <Container fluid className='work' id='solutions_2'>
                    <Row className='mx-0'>
                        <Col>
                        
                        </Col>
                        <Col lg='10' className='rightBannerWide my-3'>
                            <Container>
                                <Row>
                                    <Col className='text-start py-3'>
                                        <div>
                                            And the <span className='workBold3'>solutions</span> are:  
                                            <br></br>
                                            •	Remove the less important content.
                                            <br></br>
                                            •	Provide easy access to the frequently visited information.
                                            <br></br>
                                            •	Prioritize the major call-to-actions above the fold. 
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                        <Col className='rightBannerWideContainerFill my-3'>
                        </Col>
                    </Row>
                    <Container>
                        <Row className='pb-5 mb-5'>
                            <Col className='text-start py-5 my-5'>
                                <SingleSource className='float-end' src={`/img/${workItem.title.toLocaleLowerCase()}/homepage2.png`} />
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </section>
            <section>
                <Container fluid className='work altbg3 py-5' id='validation'>
                    <Row className='mx-0'>
                        <Col></Col>
                        <Col lg='10' className='text-center my-5 mainbg roundCol'>
                            <Container className='work'>
                                <Row>
                                    <Col lg='12' md='12' sm='12' className='text-start py-5' id="improve_1">
                                        <div className='workBoldItalic py-2 my-2'>
                                            Validation
                                        </div>
                                        <div className='workBold3 pb-2 mb-2'>
                                            Positive impact and much more to do
                                        </div>
                                        <div className='py-3'>
                                            I was able to validate that the redesign proved positive impact on experiences of making appointments and finding information. Overall, users have more effective experiences with the new design and feel visually and functionally pleasant with the new interfaces.
                                        </div>
                                        <SingleSource className='float-end' src={`/img/${workItem.title.toLocaleLowerCase()}/testresult.png`} />
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                        <Col></Col>
                    </Row>
                    <Container className='work' id='conclusion'>
                        <Row className='mx-0'>
                            <Col className='py-5 text-start altbg3Font'>
                                <span className='workItalic'>Conclusion & Afterthought</span>
                                <div className='workBold3 pb-2 mb-2'>
                                    The importance of user centered design
                                </div>
                                <br></br>
                                This is only the first step. Much more problems that had been discovered haven't been brought to the table. There are more opportunities (i.e., reorganizing the menu using tree testing) to create smooth and intuitive user experiences.
                                <br></br>
                                <br></br>
                                I'm glad to have this fulfilling and rewarding project done.
                                <span className='workBold'>&nbsp;What I learned is that a good design should always be user-centered and consider usability heuristics. </span>
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </section>
            <Container className='work workFooter'>
                <WorkFooter workPrevious={workItem.workPrevious} workNext={workItem.workNext} />
            </Container>
            <Context workPrevious={workItem.workPrevious} workNext={workItem.workNext} contextItems={workItem.contextItems} />
        </div>
    );
  }