import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from "react-router-dom";

export default function WorkFooter({workNext, workPrevious}) {   
    const _next = workNext.split("|");
    const _previous = workPrevious.split("|");

    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth' // for smoothly scrolling
        });
    };
    return (
        <>
            <Row className="py-3 d-flex flex-wrap align-items-center">
                <Col>
                    <button onClick={scrollToTop}>
                        <img alt='' src='/img/footertotop.png'/>&nbsp;Back to Top
                    </button>                    
                </Col>
            </Row>
            <Row>
                <Col>
                <Link to={`/${_previous[0]}`} className='worklink2'>
                        <Row className='d-flex flex-wrap align-items-center'>
                            <Col className="text-end px-0" lg='3' sm='3' xs='4'>
                                <span >
                                    <img alt='' className='center-block' src='/img/chevron-left.png'/>
                                </span>
                            </Col>
                            <Col className='text-start px-0'>
                                <div className="media-body">
                                    <span className='workBold'>{_previous[0]}</span>
                                    <br></br>
                                    {_previous[1]}
                                </div>
                            </Col>
                        </Row>
                    </Link>            
                </Col>
                <Col lg='6' md='4' sm='1'  xs='1'></Col>
                <Col>
                    <Link to={`/${_next[0]}`} className='worklink2'>
                        <Row className='d-flex flex-wrap align-items-center'>
                            <Col className='text-end px-0'>
                                <div className="media-body">
                                    <span className='workBold'>{_next[0]}</span>
                                    <br></br>
                                    {_next[1]}
                                </div>
                            </Col>
                            <Col className="text-start px-0" lg='3' sm='3' xs='4'>
                                <span >
                                    <img alt='' className='center-block' src='/img/chevron-right.png'/>
                                </span>
                            </Col>
                        </Row>
                    </Link>
                </Col>
            </Row>
        </>
    );
  }