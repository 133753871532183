import {
  Outlet
} from "react-router-dom";

import Header from "./components/header";
import Footer from "./components/footer";

function App() {
  return (
    <div>
      <Header />
      <div className="text-center">
        <Outlet />
      </div>
      <Footer />
    </div>
  );
}

export default App;