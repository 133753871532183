import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link, useLocation } from "react-router-dom";
import React from 'react';

export default function Context({contextItems, workNext, workPrevious}) {   
    const _next = workNext.split("|");
    const _previous = workPrevious.split("|");
    const _contextItems = [];

    const [closed, setClosed] = React.useState(false);
    const [contextBold, setContextBold] = React.useState('');
    const [top, setTop] = React.useState(0);

    const ref = React.useRef(null);
    const ref2 = React.useRef(null);

    contextItems.forEach((data ,i) => {
        const singleContext=[];
        data.steps.forEach((subdata, j) => {
            singleContext.push(
            <Row>
                <Col key={j}>
                    <a 
                        href={`#${subdata.step_link}`}
                        onClick={() => setContextBold(subdata.step_link)}
                        className={(contextBold===subdata.step_link?'workBold':'')}
                    >
                        {subdata.step_text}
                    </a>
                </Col>
            </Row>
        )})

        _contextItems.push(
            <Row className='py-2 px-2 text-start' key={i}>
                <Col>
                    {singleContext}
                </Col>
            </Row>
        )       
    })

    React.useEffect(() => {
        handleResize();

        function handleResize(){
            if(!closed){
                setTop((window.innerHeight - ref.current.clientHeight)/2);
            }
        }

        window.addEventListener('resize', handleResize)

        return _ => {
            window.removeEventListener('resize', handleResize)
        }
      }, [closed]);

    const { pathname, hash, key } = useLocation();

    React.useEffect(() => {
    if (hash === '') {
        window.scrollTo(0, 0);
    }
    else {
        setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView();
        }
        }, 0);
    }
    }, [pathname, hash, key]);

    return (
        <>
        <div ref={ref} className={"contextMenu "+(closed?'hide':'show')} style={{top:top}}>
            <Row className='px-2'>
                <Col className={"text-end py-2 contextMenuButton "+(closed?'hide':'show')}>
                    <button onClick={() => setClosed(true)}>
                        <img alt='' src='/img/cross.png' />
                    </button>
                </Col>
            </Row>
            <Row className='px-2'>
                <Col className={"text-end py-2 contextMenuButton "+(closed?'show':'hide')}>
                    <button onClick={() => setClosed(false)}>
                        <img alt='' src='/img/plus.png' />
                    </button>
                </Col>
            </Row>
            <div ref={ref2} className={"contextMenuContainer "+(closed?'hide':'show')}>
                <div>
                    {_contextItems}
                </div>
                <Row>
                    <Col className='pt-3'>
                        <Link to={`/${_previous[0]}`} className='worklink2'>
                            <Row className='d-flex flex-wrap align-items-center'>
                                <Col className="text-end px-0" lg='2'>
                                    <span>
                                        <img alt='' className='center-block' src='/img/vector_left.png'/>
                                    </span>
                                </Col>
                                <Col className='text-start px-1'>
                                    <div className="media-body">
                                        Previous
                                    </div>
                                </Col>
                            </Row>
                        </Link>            
                    </Col>
                </Row>
                <Row>
                    <Col className='pb-3'>
                        <Link to={`/${_next[0]}`} className='worklink2'>
                            <Row className='d-flex flex-wrap align-items-center'>
                                <Col className='text-end px-1'>
                                    <div className="media-body">
                                        Next
                                    </div>
                                </Col>
                                <Col className="text-start px-0" lg='2'>
                                    <span >
                                        <img alt='' className='center-block' src='/img/vector_right.png'/>
                                    </span>
                                </Col>
                            </Row>
                        </Link>
                    </Col>
                </Row>
            </div>
        </div>
        </>
    );
  }