import { Link } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import React from 'react';
import {works} from '../workconfig';

export default function Home() {
  const _works = [];
  const _works_m = [];

  const [scrollTop, setScrollTop] = React.useState(0);

  React.useEffect(() => {
    const onScroll = e => {
      setScrollTop(e.target.documentElement.scrollTop);
    };
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop]);

  const factor = scrollTop/window.innerHeight;

  const homecoverbg = 'rgba('+factor*255+' ,'+factor*255+', '+factor*255+')';

  works.forEach((data, i) => {
    const worktitle = data.Title.split("|")[0].toLowerCase();
    const workbgurl = "url('img/home/"+worktitle+".jpg')";
    const workbgurl_m = "url('img/home/"+worktitle+"_m.png')";

    _works.push(
      <div className='home-work' key={i} style={{backgroundImage:workbgurl}}>
        <Container fluid>
          <Row className='mx-0'>
            <Col className='work text-start'>
              <Row className='home-work-text-row  align-items-center'>
                <Col lg='3'>
                </Col>
                <Col>
                  <div className='hashtag pt-5'>
                    {data.Hashtag}
                  </div>
                  <div className='title pb-3'>
                    {data.Title.split("|")[0]}
                  </div>
                  <div>
                    {data.Title.split("|")[2]}
                  </div>
                  <div className='py-3'>
                    {data.Title.split("|")[1]}
                  </div>
                  <div className='py-5'>
                    <Link className='workBold2' to={`/${data.Title.split("|")[0]}`}>View project</Link>    
                  </div>                  
                </Col>
                <Col>
                </Col>
              </Row>      
            </Col>
            <Col lg='3'>
            </Col>
          </Row>
        </Container>
      </div>
    )

    _works_m.push(
      <div className='home-work home-work-m' key={(i+1)*10} style={{backgroundImage:workbgurl_m}}>
        <Container fluid>
          <Row className='mx-0 align-items-center'>
            <Col className='work text-start'>
              <Container>
                <Row className='home-work-text-row_m '>
                  <Col>
                    <div className='hashtag_m pt-5'>
                      {data.Hashtag}
                    </div>
                    <div className='title pb-3'>
                      {data.Title.split("|")[0]}
                    </div>
                    <div>
                      {data.Title.split("|")[2]}
                    </div>
                    <div className='py-3'>
                      {data.Title.split("|")[1]}
                    </div>
                    <div className='py-5'>
                      <Link className='workBold2' to={`/${data.Title.split("|")[0]}`}>View project</Link>    
                    </div>                  
                  </Col>
                </Row>
              </Container>   
            </Col>
          </Row>
        </Container>
      </div>
    )
  })
  

  return (
    <>
      <div className='home'>
          <Container fluid className='home-cover' style={{backgroundColor: homecoverbg}}>
            <Container>
              <Row className='align-items-center'>
                <Col className='text-start px-5'>
                    <div className='title'>
                      Hi, I'm Amber.
                    </div>
                    <div className='subtitle'>
                      <span className='text-muted'>Project manager, entrepreneur, </span> <span className='text-muted'>business consultant, </span><span className='workBold2'>UX designer</span> @ NYC 
                    </div>
                    <div className='scroll_down'>
                      <button onClick={()=>window.scrollTo(0, window.innerHeight)}><img src='/img/scroll_down.png' alt='' /></button>
                    </div>
                </Col>
              </Row>
            </Container>
          </Container>
        <div className='home-works lgWork'>
          {_works}
        </div>
        <div className='home-works smWork'>
          {_works_m}
        </div>
      </div>
    </>
  );
}