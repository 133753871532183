import {useLocation} from "react-router-dom";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

export default function Footer() {
    const location = useLocation();
    const pathname = location.pathname.replace("/","");
    const workPage = !(pathname===''||pathname==='about');
    let theme='dark';
    if(pathname===''){
        theme='light';
    }

    return (
        <footer className={`pt-5 ${theme}`}>
            {workPage&&
                <ul className="nav justify-content-center">
                    <li className="nav-item">
                        <a href="mailto:achen1130@gmail.com" className="nav-link px-3">
                            <img alt='' src='/img/send_dark.png' />
                        </a>
                    </li>
                    <li className="nav-item">
                        <a href="https://www.linkedin.com/in/amberschen/" target='_blank' rel="noreferrer" className="nav-link px-3">
                            <img alt='' src='/img/linkedin_dark.png' />
                        </a>
                    </li>
                </ul>
            }
            {!workPage&&
                <Container className={`py-5 my-5 ${theme}`}>
                    <Row className='py-5 my-5 pb-3'>
                        <Col className='text-center talk'>
                        {pathname===''&&
                            <div>Let's talk!</div>
                        }
                        {pathname==='about'&&
                            <div>Let's talk!</div>
                        }
                        </Col>
                    </Row>
                    <div className='lgImg'>
                        <Row className='pb-5 mb-5'>
                            <Col lg='2'></Col>
                            <Col lg='1'></Col>
                            <Col lg='3' xs='12' className='text-end'>
                                <a href="mailto:achen1130@gmail.com" className="nav-link px-5">
                                    <span className='contactinfo'>
                                        <img alt='' src={`/img/send_${theme}.png`} />&nbsp;Email
                                    </span>
                                </a>
                            </Col>
                            <Col lg='3' xs='12' className='text-start'>
                                <a href="https://www.linkedin.com/in/amberschen/" className="nav-link px-3">
                                    <span className='contactinfo'>
                                        <img alt='' src={`/img/linkedin_${theme}.png`} />&nbsp;Linkedln
                                    </span>
                                </a>
                            </Col>
                            <Col lg='1'></Col>
                            <Col lg='2'></Col>
                        </Row> 
                    </div>
                    <div className='smImg'>
                        <Row className='pb-2 mb-2'>
                            <Col className='text-center'>
                                <a href="mailto:achen1130@gmail.com" className="nav-link px-3">
                                    <span className='contactinfo'>
                                        <img alt='' src={`/img/send_${theme}.png`} />&nbsp;Email
                                    </span>
                                </a>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='text-center'>
                                <a href="https://www.linkedin.com/in/amberschen/" className="nav-link px-3">
                                    <span className='contactinfo'>
                                        <img alt='' src={`/img/linkedin_${theme}.png`} />&nbsp;Linkedln
                                    </span>
                                </a>
                            </Col>
                        </Row> 
                    </div> 
                </Container>          
            }
            <p className="text-center text-muted">Ⓒ 2023 Amber S Chen. All Rights Reserved.</p>
        </footer>
    );
  }