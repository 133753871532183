import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link, useLocation} from "react-router-dom";
import React from 'react';

export default function Header() {
    const location = useLocation();
    const pathname = location.pathname.replace("/","");
    const theme = (pathname===''||pathname==='about')?'light':'dark';
    const headerbg = (pathname==='')?'dark':'light';
    if(headerbg==='dark'){
        document.body.style.backgroundColor = '#000';
    }
    else{
        document.body.style.backgroundColor = '#fff';
    }

    const [expanded, setExpanded] = React.useState(false);

    return (
        <header className={`py-4 ${theme} header-${headerbg}`}>
            <Navbar collapseOnSelect expand="lg" expanded={expanded}>
                <Container>
                    <Navbar.Brand>
                        <Link to="/" onClick={() => setExpanded(expanded ? false : "expanded")}>
                            <img alt='' src={`/img/logo_${theme}.png`} />
                        </Link>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={() => setExpanded(expanded ? false : "expanded")}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </Navbar.Toggle>
                    <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
                        <Nav>
                            <Link to="/#work" className="text-center px-4 nav-link" onClick={() => setExpanded(expanded ? false : "expanded")}>Work</Link> 
                            <Link to="/about" className="text-center px-4 nav-link" onClick={() => setExpanded(expanded ? false : "expanded")}>About</Link>
                            <Nav.Link href="mailto:achen1130@gmail.com" className="text-center px-4" onClick={() => setExpanded(expanded ? false : "expanded")}><span className='contact'>Contact&nbsp;<img alt='' src={`/img/send_${theme}.png`} /></span></Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </header>
    );
  }