export const works=[
    {
        Title:"FOTG|Web App|E-library of main scientific references for USDA's primary conservation agency",
        Hashtag:"#Responsive Design #Research",
        ContextItems:
        [
            {
                steps:
                [
                    {
                        step_text:"Context",
                        step_link:"context"
                    },
                ]
            },
            {
                steps:
                [
                    {
                        step_text:"Discovery research",
                        step_link:"discovery_research"
                    }
                ]
            },
            {
                steps:
                [
                    {
                        step_text:"User flow",
                        step_link:"user_flow"
                    }
                ]
            },
            {
                steps:
                [
                    {
                        step_text:"Lo-fi prototype",
                        step_link:"lo-fi_prototype"
                    }
                ]
            },
            {
                steps:
                [
                    {
                        step_text:"Hi-fi prototype",
                        step_link:"hi-fi_prototype"
                    }
                ]
            },
            {
                steps:
                [
                    {
                        step_text:"Usability test",
                        step_link:"usability_test"
                    }
                ]
            },
            {
                steps:
                [
                    {
                        step_text:"Iterations",
                        step_link:"iterations"
                    }
                ]
            },
            {
                steps:
                [
                    {
                        step_text:"Final design",
                        step_link:"final_design"
                    }
                ]
            },
            {
                steps:
                [
                    {
                        step_text:"Impact",
                        step_link:"impact"
                    }
                ]
            },
            {
                steps:
                [
                    {
                        step_text:"Conclusion & Takeaways",
                        step_link:"conclusion"
                    }
                ]
            }
        ]
    },
    {
        Title:"WT System|Web app|Information management system for a major transportation agency",
        Hashtag:"#Contextual Design #Redesign",
        ContextItems:
        [
            {
                steps:
                [
                    {
                        step_text:"Context",
                        step_link:"context"
                    }
                ]
            },
            {
                steps:
                [
                    {
                        step_text:"Usability study",
                        step_link:"usability_study"
                    },
                    {
                        step_text:"Heuristic evaluation",
                        step_link:"heuristic_evaluation"
                    },
                    {
                        step_text:"Interviews",
                        step_link:"interviews"
                    }
                ]
            },
            {
                steps:
                [
                    {
                        step_text:"Personas",
                        step_link:"personas"
                    }
                ]
            },
            {
                steps:
                [
                    {
                        step_text:"Pain points",
                        step_link:"pain_points"
                    }
                ]
            },
            {
                steps:
                [
                    {
                        step_text:"Solution 1",
                        step_link:"solution_1"
                    },
                    {
                        step_text:"Solution 2",
                        step_link:"solution_2"
                    },
                    {
                        step_text:"Solution 3",
                        step_link:"solution_3"
                    },
                    {
                        step_text:"Improvements",
                        step_link:"improvements"
                    }
                ]
            },
            {
                steps:
                [
                    {
                        step_text:"Iteration",
                        step_link:"iteration"
                    }
                ]
            },
            {
                steps:
                [
                    {
                        step_text:"Impact",
                        step_link:"impact"
                    }
                ]
            },
            {
                steps:
                [
                    {
                        step_text:"Takeaways",
                        step_link:"conclusion"
                    }
                ]
            }
        ]
    },
    {
        Title:"Sipps|App & Web app|Mobile order solution for coffee shops",
        Hashtag:"#UX Strategy #Research",
        ContextItems:
        [
            {
                steps:
                [
                    {
                        step_text:"Context",
                        step_link:"context"
                    },
                ]
            },
            {
                steps:
                [
                    {
                        step_text:"Consumer app",
                        step_link:"consumer_app"
                    },
                    {
                        step_text:"Research",
                        step_link:"web_research"
                    },
                    {
                        step_text:"Personas",
                        step_link:"personas"
                    }
                ]
            },
            {
                steps:
                [
                    {
                        step_text:"Define",
                        step_link:"define"
                    },
                ]
            },
            {
                steps:
                [
                    {
                        step_text:"Ideation",
                        step_link:"ideation"
                    },
                    {
                        step_text:"Competitive audit",
                        step_link:"competitive_audit"
                    },
                    {
                        step_text:"Merchant app",
                        step_link:"merchant_app"
                    },
                    {
                        step_text:"User flow & IA",
                        step_link:"user_flow"
                    },
                    {
                        step_text:"Wireframes",
                        step_link:"wireframes"
                    }
                ]
            },
            {
                steps:
                [
                    {
                        step_text:"Prototype",
                        step_link:"prototype"
                    }
                ]
            },
            {
                steps:
                [
                    {
                        step_text:"Iterations",
                        step_link:"iterations"
                    }
                ]
            },
            {
                steps:
                [
                    {
                        step_text:"Impact",
                        step_link:"impact"
                    }
                ]
            },
            {
                steps:
                [
                    {
                        step_text:"Takeaways",
                        step_link:"conclusion"
                    }
                ]
            }
        ]
    },
    {
        Title:"Bonding|Mini program|Invite-only social network for families",
        Hashtag:"#Inclusive Design #Research",
        ContextItems:
        [
            {
                steps:
                [
                    {
                        step_text:"Context",
                        step_link:"context"
                    },
                ]
            },
            {
                steps:
                [
                    {
                        step_text:"Surveys",
                        step_link:"surveys"
                    },
                    {
                        step_text:"Personas",
                        step_link:"personas"
                    },
                ]
            },
            {
                steps:
                [
                    {
                        step_text:"Define",
                        step_link:"define"
                    },
                ]
            },
            {
                steps:
                [
                    {
                        step_text:"Ideation",
                        step_link:"ideation"
                    },
                    {
                        step_text:"Competitive audit",
                        step_link:"competitive_audit"
                    },
                ]
            },
            {
                steps:
                [
                    {
                        step_text:"Wireframe",
                        step_link:"wireframe"
                    },
                    {
                        step_text:"Iteration",
                        step_link:"iteration"
                    }
                ]
            },
            {
                steps:
                [
                    {
                        step_text:"Visual",
                        step_link:"visual"
                    }
                ]
            },
            {
                steps:
                [
                    {
                        step_text:"Prototype",
                        step_link:"prototype"
                    }
                ]
            },
            {
                steps:
                [
                    {
                        step_text:"Style guide",
                        step_link:"style_guide"
                    },
                ]
            },
            {
                steps:
                [
                    {
                        step_text:"Impact",
                        step_link:"impact"
                    },
                ]
            },
            {
                steps:
                [
                    {
                        step_text:"Takeaways",
                        step_link:"takeaways"
                    },
                ]
            }
        ]
    },
    {
        Title:"SBDC|Website|Website redesign for a business consulting agency",
        Hashtag:"#Redesign",
        ContextItems:
        [
            {
                steps:
                [
                    {
                        step_text:"Context",
                        step_link:"context"
                    },
                ]
            },
            {
                steps:
                [
                    {
                        step_text:"Usability study",
                        step_link:"usability_study"
                    },
                    {
                        step_text:"Personas",
                        step_link:"personas"
                    },
                ]
            },
            {
                steps:
                [
                    {
                        step_text:"Define",
                        step_link:"define"
                    },
                ]
            },
            {
                steps:
                [
                    {
                        step_text:"Improve #1",
                        step_link:"improve_1"
                    },
                    {
                        step_text:"Pain points",
                        step_link:"pain_points"
                    },
                    {
                        step_text:"User flow",
                        step_link:"user_flow"
                    },
                    {
                        step_text:"Solutions",
                        step_link:"solutions"
                    },
                    {
                        step_text:"Iteration",
                        step_link:"iteration"
                    },
                    {
                        step_text:"Prototype",
                        step_link:"prototype"
                    }
                ]
            },
            {
                steps:
                [
                    {
                        step_text:"Improve #2",
                        step_link:"improve_2"
                    },
                    {
                        step_text:"Pain points",
                        step_link:"pain_points_2"
                    },
                    {
                        step_text:"Solutions",
                        step_link:"solutions_2"
                    }
                ]
            },
            {
                steps:
                [
                    {
                        step_text:"Validation",
                        step_link:"validation"
                    }
                ]
            },
            {
                steps:
                [
                    {
                        step_text:"Takeaways",
                        step_link:"conclusion"
                    }
                ]
            }
        ]
    },
    /**
    {
        Title:"CommerDash|Website|Website for a digital agency",
        ContextItems:
        [
            {
                steps:
                [
                    {
                        step_text:"Context",
                        step_link:"context"
                    },
                ]
            },
            {
                steps:
                [
                    {
                        step_text:"Competitive audit",
                        step_link:"competitive_audit"
                    },
                    {
                        step_text:"Interview",
                        step_link:"interview"
                    },
                    {
                        step_text:"Empathy map",
                        step_link:"empathy_map"
                    },
                    {
                        step_text:"Personas",
                        step_link:"personas"
                    },
                ]
            },
            {
                steps:
                [
                    {
                        step_text:"Define",
                        step_link:"define"
                    },
                ]
            },
            {
                steps:
                [
                    {
                        step_text:"User flow",
                        step_link:"user_flow"
                    },
                    {
                        step_text:"Sitemap",
                        step_link:"user_flow"
                    },
                    {
                        step_text:"Wireframes",
                        step_link:"user_flow"
                    }
                ]
            },
            {
                steps:
                [
                    {
                        step_text:"Prototype",
                        step_link:"prototype"
                    }
                ]
            },
            {
                steps:
                [
                    {
                        step_text:"Style guide",
                        step_link:"style_guide"
                    }
                ]
            },
            {
                steps:
                [
                    {
                        step_text:"Validation",
                        step_link:"validation"
                    }
                ]
            },
            {
                steps:
                [
                    {
                        step_text:"Takeaways",
                        step_link:"conclusion"
                    }
                ]
            }
        ]
    },
    **/
]